import React from 'react'
import { Modal, Form, DatePicker, DatePickerProps } from 'antd'
import { useMutation } from 'react-query'
import CButton from './CButton'
import { rescheduleBooking } from '../../services/booking'
import { CloseOutlined } from '@ant-design/icons'
import dayjs from 'dayjs';
import moment from 'moment'

interface RescheduleModalProps {
  visible: boolean
  onClose: () => void
  customData: { orderId: any; serviceId: any }
  refetch: () => void
  showCustomAlert: (type: 'success' | 'error', message: string) => void
}

const RescheduleModal: React.FC<RescheduleModalProps> = ({
  visible,
  onClose,
  customData,
  refetch,
  showCustomAlert
}) => {
  const [form] = Form.useForm()

  const { mutate: reschedule, isLoading: linkLoading } = useMutation(
    rescheduleBooking,
    {
      onSuccess: response => {
        if (response.succeeded) {
          refetch()
          showCustomAlert('success', 'Custom event rescheduled successfully!')
          onClose()
        } else {
          showCustomAlert('error', response.message || 'An error occurred.')
        }
      },
      onError: (error: any) => {
        showCustomAlert(
          'error',
          error?.message || 'An error occurred. Please try again.'
        )
      }
    }
  )

  const onFinish = (values: any) => {
    const obj = {
      ...customData,
      rescheduleDate: values.rescheduleDate
    }
    reschedule(obj)
  }

  const onChange: DatePickerProps['onChange'] = date => {
    const formattedDate = moment(date.toDate()).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
    form.setFieldsValue({ rescheduleDate: formattedDate });
  }

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      footer={null}
      centered
      className='success-modal'
      title={
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '20px'
          }}
        >
          <span className='inputfield-label' style={{ fontSize: '20px' }}>
            Reschedule Booking
          </span>
          <CloseOutlined
            onClick={onClose}
            style={{ cursor: 'pointer', fontSize: '16px' }}
          />
        </div>
      }
    >
      <Form form={form} onFinish={onFinish}>
        <Form.Item
          name='rescheduleDate'
          rules={[
            { required: true, message: 'Please choose a reschedule date' }
          ]}
        >
          <label className='inputfield-label'>Reschedule Date</label>
          <DatePicker
            className='inputfield w-100'
            disabledDate={(current) => current && current.isBefore(dayjs().startOf('day'))}
            onChange={onChange}
          />
        </Form.Item>
        <CButton loading={linkLoading} htmlType='submit'>
          Reschedule
        </CButton>
      </Form>
    </Modal>
  )
}

export default RescheduleModal
