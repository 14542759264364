// import { useState } from 'react'
// import { useLocation, useNavigate, useParams } from 'react-router-dom'
// import { Theme } from '../../Theme'
// import { t } from 'i18next'
// import CustomAlert from '../../components/common/CAlert'
// import { getTheFirstTwoLetterOfString } from '../../utils/functions'

// const ViewReport: React.FC = () => {
//     const navigate = useNavigate()
//     const routeLocation = useLocation();
//     const [alert, setAlert] = useState<{
//         message: string
//         type: string
//         visible: boolean
//     }>({
//         message: '',
//         type: 'success',
//         visible: false
//     })

//     const { bookingId, customerId, customerName, vendorName, serviceName, email, totalAmount, totalSpend, transactionId, city, bookingDate, location, promoCode, registrationDate, amountDiscounted, bookingStatus, paymentStatus } = routeLocation?.state?.record || null;

//     return (
//         <>
//             <div style={{ padding: '20px 0px', color: Theme.colors.black }}>
//                 <div className='d-flex align-items-center justify-content-between'>
//                     <div className='d-flex align-items-center'>
//                         <img
//                             src={Theme.icons.icn_arrow_back}
//                             className='cursor-pointer'
//                             alt='icn_user_add'
//                             style={{ height: '20px' }}
//                             onClick={() => navigate(-1)}
//                         />
//                         <p className='list-heading m-0 mx-2'>{t(`${routeLocation?.state?.type === 'order' ? "Order" : "Customer"} Report`)}</p>
//                     </div>

//                 </div>

//             </div>
//             <>

//                 <div
//                     style={{
//                         backgroundColor: Theme.colors.white,
//                         padding: '24px',
//                         borderRadius: '16px'
//                     }}
//                 >
//                     <p className='list-heading m-0 mx-2'>{t(`${routeLocation?.state?.type === 'order' ? "Order" : "Customer"} Report Details`)}</p>

//                     <div className='view-container mt-3'>
//                         <div className='d-flex align-items-center'>
//                             <div className='bold-name-circle'>
//                                 <p className='m-0'>{getTheFirstTwoLetterOfString(customerName)}</p>
//                             </div>
//                             <p className='user-name m-0 mx-2'>
//                                 {customerName}
//                             </p>
//                         </div>
//                         <div
//                             className='row w-75 mt-3'
//                         >
//                             <div className='col-lg-4'>
//                                 <p className='detail-label m-0'>{bookingId ? t("Booking ID") : t("Customer ID")}</p>
//                                 <p className='detail-value m-0'>
//                                     {bookingId ? bookingId : customerId}
//                                 </p>
//                             </div>
//                           { routeLocation?.state?.type === 'order' &&  <div className='col-lg-4'>
//                                 <p className='detail-label m-0'>{t("Vendor Name")} </p>
//                                 <p className='detail-value m-0'>
//                                     {vendorName}
//                                 </p>
//                             </div>}
//                             <div className='col-lg-4'>
//                                 <p className='detail-label m-0'>{serviceName ? t("Service Name") : t("Email")}</p>
//                                 <p className='detail-value m-0'>
//                                     {serviceName ? serviceName : email}
//                                 </p>
//                             </div>
//                             <div className='col-lg-4 mt-3'>
//                                 <p className='detail-label m-0'>{transactionId ? t("Transaction ID") : t("City")} </p>
//                                 <p className='detail-value m-0'>
//                                     {transactionId ? transactionId : city}
//                                 </p>
//                             </div>
//                             <div className='col-lg-4 mt-3'>
//                                 <p className='detail-label m-0'>{bookingDate ? t("Booking Date") : t("Address")} </p>
//                                 <p className='detail-value m-0'>
//                                     {bookingDate ? bookingDate : location}
//                                 </p>
//                             </div>
//                             <div className='col-lg-4 mt-3'>
//                                 <p className='detail-label m-0'>{promoCode || promoCode === null ? t("Promo Code") : t("Registration Date")} </p>
//                                 <p className='detail-value m-0'>
//                                     {promoCode || promoCode === null ? promoCode : registrationDate}
//                                 </p>
//                             </div>
//                             <div className='col-lg-4 mt-3'>
//                                 <p className='detail-label m-0'>{totalAmount ? t("Total Amount") : t("Total Spent")} </p>
//                                 <p className='detail-value m-0'>
//                                     {totalAmount ? totalAmount : totalSpend}
//                                 </p>
//                             </div >
//                          {   routeLocation?.state?.type === 'order' &&(<>
//                           <div className='col-lg-4 mt-3'>
//                                 <p className='detail-label m-0'>{t("Booking Status")} </p>
//                                 <p className='detail-value m-0'>
//                                     {bookingStatus}
//                                 </p>
//                             </div>
//                             <div className='col-lg-4 mt-3'>
//                                 <p className='detail-label m-0'>{t("Payment Status")} </p>
//                                 <p className='detail-value m-0'>
//                                     {paymentStatus}
//                                 </p>
//                             </div>
//                             </>)}
//                         </div>

//                     </div>
//                 </div>
//             </>

//             <CustomAlert
//                 message={alert.message}
//                 type={alert.type as 'success' | 'info' | 'warning' | 'error'}
//                 visible={alert.visible}
//                 onClose={() => setAlert({ ...alert, visible: false })}
//                 duration={3000}
//             />
//         </>
//     )
// }

// export default ViewReport

import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Theme } from '../../Theme'
import { t } from 'i18next'
import CustomAlert from '../../components/common/CAlert'
import { reportConfig, ReportType } from './ViewReportsConfig'
import { getTheFirstTwoLetterOfString } from '../../utils/functions'

const ViewReport: React.FC = () => {
  const navigate = useNavigate()
  const routeLocation = useLocation()
  const [alert, setAlert] = React.useState({
    message: '',
    type: 'success',
    visible: false
  })

  const record = routeLocation?.state?.record || {}
  const type = routeLocation?.state?.type || ''
  //   const displayName = routeLocation?.state?.displayName || ''

  console.log('record---------in view report', record)

  const reportType: ReportType = type ? type : 'order'
  const reportFields = reportConfig[reportType]

  return (
    <>
      <div style={{ padding: '20px 0px', color: Theme.colors.black }}>
        <div className='d-flex align-items-center justify-content-between'>
          <div className='d-flex align-items-center'>
            <img
              src={Theme.icons.icn_arrow_back}
              className='cursor-pointer'
              alt='icn_user_add'
              style={{ height: '20px' }}
              onClick={() => navigate(-1)}
            />
            <p
              className='list-heading m-0 mx-2'
              style={{ textTransform: 'capitalize' }}
            >
              {t(`${reportType} Report`)}
            </p>
          </div>
        </div>
      </div>

      <div
        style={{
          backgroundColor: Theme.colors.white,
          padding: '24px',
          borderRadius: '16px'
        }}
      >
        <p
          className='list-heading m-0 mx-2'
          style={{ textTransform: 'capitalize' }}
        >
          {t(`${reportType} Report Details`)}
        </p>

        <div className='view-container mt-3'>
          <div className='row'>
            {reportFields?.nameField && (
              <div className='d-flex align-items-center'>
                <div className='bold-name-circle'>
                  <p className='m-0'>
                    {getTheFirstTwoLetterOfString(
                      record[reportFields?.nameField]
                    )}
                  </p>
                </div>
                <p className='user-name m-0 mx-2'>
                  {record[reportFields?.nameField]}
                </p>
              </div>
            )}
            {reportFields.fields.map(field => (
              <div className='col-lg-4 mt-3' key={field.key}>
                <p className='detail-label m-0'>{t(field.label)}</p>
                <p className='detail-value m-0'>{record[field.key] || '-'}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      <CustomAlert
        message={alert.message}
        type={alert.type as 'success' | 'info' | 'warning' | 'error'}
        visible={alert.visible}
        onClose={() => setAlert({ ...alert, visible: false })}
        duration={3000}
      />
    </>
  )
}

export default ViewReport
