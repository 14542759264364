import React, { useState, useEffect } from "react";
import CustomTabs from "../../../components/common/CTabs";
import CInput from "../../../components/common/CInput";
import { Theme } from "../../../Theme";
import CTable from "../../../components/common/CTable";
import { ColumnsType } from 'antd/lib/table';
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DatePicker, Switch, Tag } from "antd";
import { changeCustomerStatus, getAllCustomers } from "../../../services/customer";
import AlertModal from "../../../components/common/AlertModal";
import CustomAlert from "../../../components/common/CAlert";
import _ from 'lodash';
import dayjs from 'dayjs';
import { RangePickerProps } from "antd/es/date-picker";

const tabs = [
    { key: 'AllUser', label: 'All Users' },
    { key: 'Active', label: 'Active Users' },
    { key: 'InActive', label: 'Inactive Users' },
];

const ManageCustomerList: React.FC = () => {
    const [userWillActive, setUserWillActive] = useState<{ show: boolean; status: boolean; id: number | null }>({
        show: false,
        status: false,
        id: null,
    });
    const [alert, setAlert] = useState<{ message: string; type: 'success' | 'error'; visible: boolean }>({
        message: '',
        type: 'success',
        visible: false,
    });
    const [showDateRangePicker, setShowDateRangePicker] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const { RangePicker } = DatePicker;

    // Get activeKey, pageNumber, and pageSize from URL search params
    const activeKey = searchParams.get('Status') || 'AllUser';
    const pageNumber = Number(searchParams.get('PageNumber')) || 1;
    const pageSize = Number(searchParams.get('PageSize')) || 10;
    const search = searchParams.get('Search') || "";
    const startDate = searchParams.get('StartDate') || "";
    const endDate = searchParams.get('EndDate') || "";

    // Update search params when the tab changes
    const handleTabChange = (key: string) => {
        setSearchParams({ Status: key, PageNumber: '1', PageSize: pageSize.toString() }, { replace: true });
    };

    // Pagination handling
    const handleTableChange = (page: number, pageSize: number) => {
        setSearchParams({ Status: activeKey, PageNumber: page.toString(), PageSize: pageSize.toString() }, { replace: true });
    };

    const handleSearchChange = _.debounce((e: any) => {
        setSearchParams({ Status: activeKey, PageNumber: '1', PageSize: pageSize.toString(), Search: e.target.value }, { replace: true });
    }, 1000)

    const handleSwitchChange = (user: any) => {
        setUserWillActive({ show: true, status: user?.active, id: user?.id });
    };

    const columns: ColumnsType = [
        {
            title: t("ID"),
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: t("user_name"),
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: t("email_address"),
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: t("date_of_joining"),
            dataIndex: 'joiningDate',
            key: 'joiningDate',
            render: (text, record) => (
                <p>{dayjs(text)?.format('DD.MM.YYYY')}</p>
            )
        },
        {
            title: t("status"),
            dataIndex: 'roleId',
            key: 'roleName',
            render: (text, record) => (
                <Tag
                    color={record?.active ? Theme.colors.success : 'red'}
                    className="status-tag"
                    style={{ borderColor: record?.active ? Theme.colors.success : 'red' }}
                >
                    <span style={{ fontSize: '20px', lineHeight: '1', color: record?.active ? Theme.colors.success : 'red' }}>•</span>
                    <span style={{ color: record?.active ? Theme.colors.success : 'red' }}>{record?.active ? t("Active") : t("Inactive")}</span>
                </Tag>
            )
        },
        {
            title: t('action'),
            render: (text, record) => (
                <div className="d-flex  align-items-center">
                    <img
                        src={Theme.icons.icn_view_list}
                        alt="icn_user_add"
                        style={{ height: '20px' }}
                        className='cursor-pointer'
                        onClick={() => navigate('/jive/viewUser', { state: { record, module: 'customer' } })}
                    />
                    <Switch
                        checked={record?.active}
                        onChange={() => handleSwitchChange(record)}
                        className='mx-2'
                    />
                </div>
            ),
            key: 'action',
        },
    ];

    const { data, error, isLoading } = useQuery(
        ['customerUsers', activeKey, pageNumber, pageSize, search, startDate, endDate],
        () => getAllCustomers({ Status: activeKey, PageNumber: pageNumber, PageSize: pageSize, Search: search, StartDate: startDate, EndDate: endDate }),
        {
            keepPreviousData: true,
            onError: (err: any) => {
                setAlert({ message: err?.message || t("An error occurred"), type: 'error', visible: true });
            },
        }
    );

    const { mutate, isLoading: statusLoading } = useMutation(changeCustomerStatus, {
        onMutate: async (newStatus: any) => {
            await queryClient.cancelQueries(['customerUsers', activeKey]);
            const previousData = queryClient.getQueryData(['customerUsers', activeKey]);
            return { previousData };
        },
        onError: (err: any, newStatus, context) => {
            queryClient.setQueryData(['customerUsers', activeKey], context?.previousData);
            setAlert({ message: err?.message || t("Failed to update status"), type: 'error', visible: true });
        },
        onSettled: () => {
            queryClient.invalidateQueries(['customerUsers', activeKey]);
        },
        onSuccess: () => {
            setAlert({ message: t("Status Updated successfully!"), type: 'success', visible: true });
        },
    });

    const handleModalOk = () => {
        if (userWillActive.id !== null) {
            const newStatus = !userWillActive.status;
            mutate({ id: userWillActive.id, status: newStatus });
        }
        setUserWillActive({ show: false, status: false, id: null });
    };

    const handleModalCancel = () => {
        setUserWillActive({ show: false, status: false, id: null });
    };

    const disabledDate: RangePickerProps['disabledDate'] = (current) => {
        // Can not select days before today and today
        return current && current > dayjs().endOf('day');
    };

    const handleSelect = (ranges: any) => {
        if (ranges) {
            const StartDatePicker = dayjs(ranges && ranges[0]).format('YYYY-MM-DD');
            const EndDatePicker = dayjs(ranges && ranges[1]).format('YYYY-MM-DD');

            setSearchParams({ StartDate: StartDatePicker, EndDate: EndDatePicker, Status: activeKey, PageNumber: '1', PageSize: pageSize.toString() }, { replace: true });

        }
        setShowDateRangePicker(!showDateRangePicker);
    };
    return (
        <>
            <p className="page-heading">{t("manage_customer")}</p>
            <CustomTabs tabs={tabs} activeKey={activeKey} onTabChange={handleTabChange} />

            <div className="d-flex justify-content-between align-items-center">
                <p className="m-0 list-heading">{t("all_customers_list")}</p>
                <div className="d-flex justify-content-between" style={{position:'relative'}}>
                    <CInput
                        prefix={<img alt="" src={Theme.icons.icn_search} style={{ height: "16px" }} />}
                        className="searchInput"
                        placeholder={t("Search")}
                        onChange={handleSearchChange}
                    />
                    <div className="calendar-div" onClick={() => setShowDateRangePicker(!showDateRangePicker)}
                        style={{ cursor: 'pointer' }}>
                        <img src={Theme.icons.icn_white_calendar} alt="icn_user_add" style={{ height: '20px' }} />
                    </div>
                    {showDateRangePicker && (
                        <div style={{ position: 'absolute', zIndex: 1000, marginTop: '50px' }}>
                            <RangePicker onChange={handleSelect} disabledDate={disabledDate} style={{ height: '50px' }} />
                        </div>
                    )}
                </div>
            </div>
            <CTable
                footer="End of Table"
                columns={columns}
                dataSource={data?.data}
                pagination={{
                    showSizeChanger: false,
                    showQuickJumper: false,
                    current: pageNumber,
                    pageSize: pageSize,
                    total: data?.totalRecords,
                    onChange: handleTableChange,
                    showTotal: (total: number, range: [number, number]) => (
                        <p>{`Showing ${range[0]} - ${range[1]} of ${total} Results`}</p>
                    ),
                    itemRender: (page, type, originalElement) => {
                        if (type === 'prev') {
                            return <p className="detail-label">{`< Prev`}</p>;
                        }
                        if (type === 'next') {
                            return <p className="detail-label">{`Next >`}</p>;
                        }
                        return originalElement;
                    }
                }}
                loading={isLoading}
            />

            <AlertModal
                visible={userWillActive?.show}
                message={t(`Are you sure you want to ${userWillActive?.status ? 'deactivate' : 'activate'} this user?`)}
                onDelete={handleModalOk}
                onCancel={handleModalCancel}
            />
            <CustomAlert
                message={alert.message}
                type={alert.type}
                visible={alert.visible}
                onClose={() => setAlert({ ...alert, visible: false })}
                duration={3000}
            />
        </>
    );
};

export default ManageCustomerList;
