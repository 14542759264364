import {
  Input,
  Form,
  DatePickerProps,
  DatePicker,
  TimePicker,
  Row,
  Col
} from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'
import { useNavigate, useLocation, useParams } from 'react-router-dom'

import { get, getVendorsList, post } from '../../../services/booking'
import { Theme } from '../../../Theme'
import CInput from '../../../components/common/CInput'
import CustomSelect from '../../../components/common/CSelect'
import CButton from '../../../components/common/CButton'
import SuccessModal from '../../../components/common/SuccessModal'
import CustomAlert from '../../../components/common/CAlert'
import dayjs from 'dayjs'
import moment from 'moment'
import { getAllEvents } from '../../../services/lov'

const AdminBookingForm: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const { TextArea } = Input
  const [isSuccess, setIsSuccess] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)

  const [vendorServiceList, setVendorServiceList] = useState<any[]>([])
  const [selectedVendor, setSelectedVendor] = useState<{ [key: string]: any }>(
    {}
  )
  const [eventType, setEventType] = useState<{ [key: string]: any }>({})
  const [selectedvendorService, setSelectedvendorService] = useState<{
    [key: string]: any
  }>({})
  const [storeTime, setStoreTime] = useState({ start_time: '', end_time: '' })
  const [alert, setAlert] = useState<{
    message: string
    type: string
    visible: boolean
  }>({
    message: '',
    type: 'success',
    visible: false
  })
  const { type, id, requestId } = useParams()

  console.log('type, id, requestId ====>>>', type, id, requestId)

  console.log('Please choose a service.=====>>>', selectedvendorService)
  const {
    data: VendorList,
    error: VendorListError,
    isLoading: VenodLoading
  } = useQuery('vendorList', getVendorsList)

  const {
    data: EventTypes,
    error: EventTypesError,
    isLoading: EventTypesLoading
  } = useQuery('EventTypes', getAllEvents)

  const {
    mutate: fetchVendorServices,
    isLoading: isFetchingCustomEventDetails
  } = useMutation(
    (id: any) => get('AdminService/GetServiceByVendorId', { vendorId: id }),
    {
      onSuccess: response => {
        if (response.succeeded) {
          setVendorServiceList(response.data)
        } else {
          setAlert({ message: response.message, type: 'error', visible: true })
        }
      },
      onError: (error: any) => {
        setAlert({
          message: error?.message || 'An error occurred. Please try again.',
          type: 'error',
          visible: true
        })
      }
    }
  )
  form.setFieldsValue({
    currencyCode: 'SAR'
  })
  const onChangeVendor = async (vendorId: any) => {
    form.setFieldsValue({
      serviceId: undefined,
      categoryName: undefined,
      description: undefined
    })
    fetchVendorServices(vendorId)
    let vn = VendorList.data.find(
      (vendor: { phoneNumber: any; email: any; id: any }) =>
        vendor.id == vendorId
    )
    setSelectedVendor(vn)
    form.setFieldsValue({
      vendorPhoneNumber: vn.phoneNumber,
      vendorEmail: vn.email
    })
  }

  const onChangeEventType = (type: any) => {
    let eventType = EventTypes.data.find(
      (et: { id: any; name: any }) => et.id == type
    )
    setEventType(eventType)
  }

  const onChangeVendorService = (serviceId: any) => {
    let sv = vendorServiceList.find(service => service.id == serviceId)
    setSelectedvendorService(sv)
    console.log('sv===>>>', sv)
    form.setFieldsValue({
      description: sv.description,
      categoryName: sv?.categoryName,
      servicePrice: sv?.price
    })
  }

  const getData = (val: any) => {
    console.log('val', val)

    if (!val) return []
    return val.data.map((x: any) => ({
      value: x.id,
      label: x.name
        ? x.name
        : x.contactPersonName
        ? x.contactPersonName
        : x.serviceName
    }))
  }

  const handleSave = async (data: any) => {
    const { serviceRequestDate, ...newObj } = data
    setSubmitLoading(true)
    const obj = {
      ...newObj,
      serviceRequestDate: serviceRequestDate.format('YYYY-MM-DD')
    }
    setSubmitLoading(false)

    console.log('obj-----------------------------', obj)
    if (formType == 'update') {
      obj['id'] = record.id
      obj['orderReference'] = record?.orderReference
    }
    let response = await post('AdminServiceOrder/AdminCreateServiceOrder', obj)
    if (response.succeeded) {
      setIsSuccess(true)
    } else {
      setAlert({ message: response.message, type: 'error', visible: true })
    }
  }
  const { record, formType } = location.state

  console.log('record---------------------', location.state)

  useEffect(() => {
    if (record) {
      // form.setFieldsValue({
      //   vendorId: record?.vendorName,
      //   phoneNumber: record?.vendorPhoneNumber,
      //   vendorEmail: record?.vendorEmail,
      //   serviceId: record?.serviceName,
      //   description: record?.description,
      //   eventTypeId: record?.eventType,
      //   categoryName: record?.categoryName,
      //   name: record?.customerName,
      //   noOfAttendees: record?.noOfAttendees,
      //   serviceRequestDate: dayjs(record?.serviceRequestDate),
      //   orderStartTime: record?.serviceStartTime,
      //   orderEndTime: record?.serviceEndTime,
      //   userAddress: record?.userAddress,
      //   servicePrice: record?.servicePrice
      // })
      const { ...newObj } = record
      fetchVendorServices(record?.vendorId)
      form.setFieldsValue(newObj)
      form.setFieldsValue({
        serviceRequestDate: dayjs(record?.serviceRequestDate),
        name: record?.customerName,
        email: record?.customerEmail
      })
      
      // setTimeout(() => {
      // onChangeVendorService(record?.serviceId);
      // }, 1000);

    }
  }, [])

  // useEffect(() => {
  //   if (record && VendorList?.data?.length > 0) {
  //     onChangeVendor(record.vendorId)
  //   }
  //   if (record && vendorServiceList?.length > 0) {
  //     onChangeVendorService(record.serviceId)
  //   }
  //   if (record && EventTypes?.data?.length > 0) {
  //     onChangeEventType(record.eventTypeId)
  //   }
  // }, [record, VendorList, vendorServiceList, EventTypes])

  const onChange: DatePickerProps['onChange'] = (date, dateString) => {
    // if (date) {
    //     const formattedDate = dayjs(date).format('YYYY-MM-DD[T]HH:mm:ss');
    //     form.setFieldsValue({ serviceRequestDate: formattedDate })
    // }
  }

  const onTimeChange: (
    time: any,
    timeString: string | string[],
    type: 'start_time' | 'end_time'
  ) => void = (time, timeString, type) => {
    console.log(time, timeString)
    const selectedTime = Array.isArray(timeString)
      ? timeString.join(', ')
      : timeString
    if (type === 'start_time') {
      setStoreTime({ ...storeTime, start_time: selectedTime })
    } else {
      setStoreTime({ ...storeTime, end_time: selectedTime })
    }
    form.setFieldsValue({
      [type === 'start_time' ? 'orderStartTime' : 'orderEndTime']: selectedTime
    })
  }
  function getDisabledHours () {
    const parsedTime = moment(storeTime?.start_time, 'h:mm A')
    var hours = []
    for (let i = 0; i < parsedTime.hour() + 1; i++) {
      hours.push(i)
    }
    return hours
  }
  return (
    <>
      <div style={{ padding: '20px', color: Theme.colors.black }}>
        <div className='d-flex align-items-center mb-4'>
          <img
            src={Theme.icons.icn_arrow_back}
            className='cursor-pointer'
            alt='icn_user_add'
            style={{ height: '20px' }}
            onClick={() => navigate(-1)}
          />
          <p className='list-heading m-0 mx-2'>{'Admin Booking'}</p>
        </div>
        <Form
          form={form}
          layout='vertical'
          onFinish={handleSave}
          style={{
            backgroundColor: Theme.colors.white,
            padding: '24px',
            borderRadius: '16px'
          }}
        >
          <p className='user-name'>{'Booking Details'}</p>
          {/* {isLoading || isCountryLoading ? (
                        <div className='d-flex align-items-center justify-content-center' style={{ height: '200px' }} >
                            <Spin />
                        </div>
                    ) : ( */}
          <>
            <div className='row' style={{ width: '90%' }}>
              <div className='col-lg-4'>
                <Form.Item
                  name='name'
                  rules={[
                    {
                      required: true,
                      message: t('Please enter the customer name')
                    }
                  ]}
                >
                  <CInput
                    label={t('Customer Name')}
                    placeholder={t('Enter Customer Name')}
                  />
                </Form.Item>
              </div>
              <div className='col-lg-4'>
                <Form.Item
                  name='email'
                  rules={[
                    {
                      required: true,
                      message: t('Please enter the customer email')
                    }
                  ]}
                >
                  <CInput
                    label={t('Customer Email')}
                    placeholder={t('Enter Customer Email')}
                  />
                </Form.Item>
              </div>
              <div className='col-lg-4'>
                <Form.Item
                  name='phoneNumber'
                  rules={[
                    {
                      required: true,
                      message: t('Please enter the customer phone')
                    }
                  ]}
                >
                  <CInput
                    label={t('Customer Phone')}
                    placeholder={t('Enter Customer Phone')}
                  />
                </Form.Item>
              </div>
              <div className='col-lg-4'>
                <Form.Item
                  name='vendorId'
                  rules={[
                    { required: true, message: t('Please choose a vendor') }
                  ]}
                >
                  <CustomSelect
                    label={t('Vendor Name')}
                    options={getData(VendorList)}
                    value={selectedVendor.id}
                    onChange={value => onChangeVendor(value)}
                    placeholder={t('Choose Vendor')}
                  />
                </Form.Item>
              </div>

              <div className='col-lg-4'>
                <Form.Item name='vendorPhoneNumber'>
                  <CInput
                    disabled={true}
                    label={t('Phone Number')}
                    placeholder={t('Enter Phone Number')}
                  />
                </Form.Item>
              </div>

              <div className='col-lg-4'>
                <Form.Item name='vendorEmail'>
                  <CInput
                    disabled={true}
                    label={t('Vendor Email')}
                    placeholder={t('Enter Email')}
                  />
                </Form.Item>
              </div>

              <div className='col-lg-4'>
                <label
                  className='inputfield-label'
                  style={{ display: 'block', marginBottom: '5px' }}
                >
                  {t('Event Date')}
                </label>
                <Form.Item
                  name='serviceRequestDate'
                  rules={[
                    {
                      required: true,
                      message: t('Please enter a event date')
                    }
                  ]}
                >
                  <DatePicker
                    disabledDate={current =>
                      current && current.isBefore(dayjs().startOf('day'))
                    }
                    className='inputfield w-100'
                    onChange={onChange}
                  />
                </Form.Item>
              </div>
              <div className='col-lg-4'>
                <Form.Item
                  name='orderStartTime'
                  rules={[
                    {
                      required: true,
                      message: t('Please enter the start time')
                    }
                  ]}
                >
                  <label
                    className='inputfield-label'
                    style={{ display: 'block', marginBottom: '5px' }}
                  >
                    {t('Booking Start Time')}
                  </label>
                  <TimePicker
                    className='inputfield w-100'
                    defaultValue={
                      record?.serviceRequestStartTime
                        ? dayjs(record?.serviceRequestStartTime, 'hh:mm A')
                        : null
                    }
                    use12Hours
                    format='hh:mm A'
                    onChange={(time, timeString) =>
                      onTimeChange(time, timeString, 'start_time')
                    }
                  />
                </Form.Item>
              </div>
              <div className='col-lg-4'>
                <Form.Item
                  name='orderEndTime'
                  rules={[
                    { required: true, message: t('Please enter the end time') }
                  ]}
                >
                  <label
                    className='inputfield-label'
                    style={{ display: 'block', marginBottom: '5px' }}
                  >
                    {t('Booking End Time')}
                  </label>
                  <TimePicker
                    className='inputfield w-100'
                    defaultValue={
                      record?.serviceRequestEndTime
                        ? dayjs(record?.serviceRequestEndTime, 'hh:mm A')
                        : null
                    }
                    disabledTime={() => ({
                      disabledHours: getDisabledHours
                    })}
                    use12Hours
                    format='hh:mm A'
                    onChange={(time, timeString) =>
                      onTimeChange(time, timeString, 'end_time')
                    }
                  />
                </Form.Item>
              </div>

              <div className='col-lg-4'>
                <Form.Item
                  name='noOfAttendees'
                  rules={[
                    {
                      required: true,
                      message: t('Please enter the attendees')
                    }
                  ]}
                >
                  <CInput
                    label={t('No of Attendees')}
                    placeholder={t('Enter attendees')}
                  />
                </Form.Item>
              </div>

              <div className='col-lg-4'>
                <Form.Item
                  name='serviceId'
                  rules={[
                    { required: true, message: t('Please choose a service') }
                  ]}
                >
                  <CustomSelect
                    label={t('Service')}
                    options={
                      vendorServiceList.length > 0
                        ? getData({ data: vendorServiceList })
                        : []
                    }
                    value={selectedvendorService.id}
                    onChange={value => onChangeVendorService(value)}
                    placeholder={t('Choose Service')}
                  />
                </Form.Item>
              </div>
              <div className='col-lg-4'>
                <Form.Item
                  name='categoryName'
                  rules={[
                    { required: true, message: t('Please choose a category') }
                  ]}
                >
                  <CInput
                    disabled={true}
                    label={t('Category Name')}
                    placeholder={t('Enter Category Name')}
                  />
                </Form.Item>
              </div>
              <div className='col-lg-4'>
                <Form.Item
                  name='currencyCode'
                  rules={[
                    {
                      required: true,
                      message: t('Please enter the currency')
                    }
                  ]}
                >
                  <CInput
                    disabled={true}
                    label={t('Currency')}
                    placeholder={t('Enter Currency')}
                  />
                </Form.Item>
              </div>
              <div className='col-lg-4'>
                <Form.Item
                  name='servicePrice'
                  rules={[
                    {
                      required: true,
                      message: t('Please enter the price')
                    }
                  ]}
                >
                  <CInput
                    disabled={true}
                    label={t('Price')}
                    placeholder={t('Enter Price')}
                  />
                </Form.Item>
              </div>
              <div className='col-lg-4'>
                <Form.Item
                  name='eventTypeId'
                  rules={[
                    { required: true, message: t('Please choose a Event Type') }
                  ]}
                >
                  <CustomSelect
                    label={t('Event Type')}
                    options={getData(EventTypes)}
                    value={eventType.id}
                    onChange={value => onChangeEventType(value)}
                    placeholder={t('Choose Event Type')}
                  />
                </Form.Item>
              </div>

              <div className='col-lg-4'>
                <Form.Item name='userAddress'>
                  <CInput
                    label={t('Location')}
                    placeholder={t('Enter Location')}
                  />
                </Form.Item>
              </div>
              <div className='col-lg-8'>
                <label
                  className='inputfield-label'
                  style={{ display: 'block', marginBottom: '5px' }}
                >
                  {t('Description')}
                </label>
                <Form.Item
                  name='description'
                  rules={[
                    {
                      required: true,
                      message: t('Please enter the description')
                    }
                  ]}
                >
                  <TextArea
                    disabled={true}
                    showCount
                    autoSize={{ minRows: 4, maxRows: 8 }}
                    maxLength={600}
                    placeholder='Write some description'
                    style={{ height: 200 }}
                  />
                </Form.Item>
              </div>
              {selectedvendorService?.serviceImages?.length > 0 && (
                <div className='col-lg-12'>
                  <label
                    className='inputfield-label'
                    style={{ display: 'block', marginBottom: '5px' }}
                  >
                    {t('Service Images')}
                  </label>
                  <div className='row'>
                    {selectedvendorService.serviceImages.map(
                      (file: any, index: number) => (
                        <div className='col-lg-3' key={index + 1}>
                          <div
                            className='d-flex align-items-center p-3 preview-image-view-container mt-3'
                            style={{ border: '1px solid #c7c6c6' }}
                          >
                            <div className='d-flex align-items-center gap-3'>
                              <img
                                src={
                                  process.env.REACT_APP_S3_BUCKET_URL +
                                  file.path
                                }
                                alt={'Service image' + index}
                                className='category-icon mb-0'
                                style={{ width: '50px', height: '50px' }}
                              />
                              {'Service image ' + (index + 1)}
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
              )}
            </div>

            <Row gutter={8} style={{ marginTop: '15px' }}>
              <Col span={4}>
                <CButton className='cancelBtn' onClick={() => navigate(-1)}>
                  Cancel
                </CButton>
              </Col>
              <Col span={4}>
                <CButton
                  loading={submitLoading}
                  style={{ width: '100%' }}
                  htmlType='submit'
                >
                  {formType == 'update' ? 'Update' : 'Save'}
                </CButton>
              </Col>
            </Row>
          </>
          {/* )} */}
        </Form>
        <SuccessModal
          visible={isSuccess}
          message={
            'You have successfully Submitted booking request to the vendor'
          }
          onOk={() => navigate(`/jive/adminBooking`)}
        />
        <CustomAlert
          message={alert.message}
          type={alert.type as 'success' | 'info' | 'warning' | 'error'}
          visible={alert.visible}
          onClose={() => setAlert({ ...alert, visible: false })}
          duration={3000}
        />
      </div>
    </>
  )
}

export default AdminBookingForm
