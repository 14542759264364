import React from 'react';
import { Tag } from 'antd'; // Assuming you're using Ant Design

type StatusConfig = {
  status: string;
  color: string;
  backgroundColor?: string;
  borderColor?: string;
  icon?: string;
  statusText?: string;
  statusToShow?:string
};

const statusConfig: StatusConfig[] = [
  { status: 'Pending', color: '#F95668', backgroundColor: '', borderColor: '#F95668' },
  { status: 'Reject', color: '#F95668', backgroundColor: '', borderColor: '#F95668', icon: '' },
  { status: 'Rejected', color: '#F95668', backgroundColor: '', borderColor: '#F95668', icon: '' },
  { status: 'Approved', color: '#09C97F', backgroundColor: '', borderColor: '#09C97F', icon: '' },
  { status: 'VendorApproved', color: '#09C97F', backgroundColor: '', borderColor: '#09C97F', icon: '', statusToShow: 'Vendor Approved' },
  { status: 'Paid', color: '#09C97F', backgroundColor: '', borderColor: '#09C97F', icon: '' },
  { status: 'Success', color: '#09C97F', backgroundColor: '', borderColor: '#09C97F', icon: '' },
  { status: 'Failed', color: '#F95668', backgroundColor: '', borderColor: '#F95668', icon: '' },
  { status: 'Active', color: '#09C97F', backgroundColor: '', borderColor: '#09C97F', icon: '' },
  { status: 'Inactive', color: '#F95668', backgroundColor: '', borderColor: '#F95668', icon: '' },
  { status: 'AdminSubmitted', color: '#09C97F', backgroundColor: '', borderColor: '#09C97F', icon: '',statusToShow: 'Admin Submitted'},
  { status: 'EventCompleted', color: '#09C97F', backgroundColor: '', borderColor: '#09C97F', icon: '',statusToShow: 'Event Completed'},
  { status: 'OrderPrepartion', color: '#D7CE4D', backgroundColor: '', borderColor: '#D7CE4D', icon: '',statusToShow: 'Preparation In progress'},
  { status: 'TeamEnroute', color: '#D194E0', backgroundColor: '', borderColor: '#D194E0', icon: '',statusToShow: 'Team Enroute'},
  { status: 'EventExecuted', color: '#568AD8', backgroundColor: '', borderColor: '#568AD8', icon: '',statusToShow: 'Event Execution'},
  { status: 'Expired', color: '#F95668', backgroundColor: '', borderColor: '#F95668', icon: '' },

];

type StatusProps = {
  status: string;
};

const Status: React.FC<StatusProps> = ({ status }) => {
  const statusItem = statusConfig.find((item) => item.status === status);

  if (!statusItem) return null;

  return (
    <Tag
      color={statusItem.backgroundColor || 'transparent'} // Fallback to transparent if no background color is provided
      className="status-tag"
      style={{
        borderColor: statusItem.borderColor ? statusItem.borderColor : 'transparent',
        color: statusItem.color,
      }}
      title={statusItem.statusText || ''}
    >
      <span style={{
            backgroundColor: statusItem.color,
            width: '7px',
            height: '7px',
            borderRadius: '50%',
            display: 'flex',
            alignItems:'center',
            justifyContent:'center'
          }}></span>
      <span
        style={{
          color: statusItem.color,
        }}
      >
        {statusItem?.statusToShow ? statusItem?.statusToShow : statusItem?.status}
      </span>
    </Tag>
  );
};

export default Status;
