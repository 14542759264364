import React, { useEffect, useState } from 'react'
import CustomTabs from '../../components/common/CTabs'
import CInput from '../../components/common/CInput'
import { Theme } from '../../Theme'
import CTable from '../../components/common/CTable'
import { ColumnsType } from 'antd/lib/table'
import { useQuery } from 'react-query'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import CustomAlert from '../../components/common/CAlert'
import _ from 'lodash'
import { get } from '../../services/api/apiService'
import Status from '../../components/common/Status'
import dayjs from 'dayjs'

const tabs = [
  { key: 'All', label: 'All' },
  {
    key: 'OrderPrepartion',
    label: 'Preparation In progress'
  },
  { key: 'TeamEnroute', label: 'Team Enroute' },
  { key: 'EventExecuted', label: 'Event Execution' },
  { key: 'EventCompleted', label: 'Event Completed' }
]

const OrderTracking: React.FC = () => {
  const [alert, setAlert] = useState<{
    message: string
    type: 'success' | 'error'
    visible: boolean
  }>({
    message: '',
    type: 'success',
    visible: false
  })
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const activeKey = searchParams.get('Status') || 'All'
  const pageNumber = Number(searchParams.get('PageNumber')) || 1
  const pageSize = Number(searchParams.get('PageSize')) || 10
  const search = searchParams.get('Search') || ''
  const sortOrder = searchParams.get('SortOrder') || 'z'

  const handleTabChange = (key: string) => {
    setSearchParams(
      { Status: key, PageNumber: '1', PageSize: pageSize.toString() },
      { replace: true }
    )
  }

  // Pagination handling
  const handleTableChange = (page: number, pageSize: number) => {
    console.log('active key', activeKey)

    setSearchParams(
      {
        Status: activeKey,
        PageNumber: page.toString(),
        PageSize: pageSize.toString()
      },
      { replace: true }
    )
  }

  const handleSearchChange = _.debounce((e: any) => {
    setSearchParams(
      {
        Status: activeKey,
        PageNumber: '1',
        PageSize: pageSize.toString(),
        Search: e.target.value
      },
      { replace: true }
    )
  }, 1000)

  const handleSorting = () => {
    const newSortOrder = sortOrder === 'z' ? 'a' : 'z'
    setSearchParams(
      {
        Status: activeKey,
        PageNumber: pageNumber.toString(),
        PageSize: pageSize.toString(),
        Search: search,
        SortOrder: newSortOrder
      },
      { replace: true }
    )
  }

  const columns: ColumnsType = [
    {
      title: t('Order ID'),
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: t('Customer Name'),
      dataIndex: 'customerName',
      key: 'customerName'
    },
    {
      title: t('Service'),
      render: (text, record) => (
        <>{`${record?.serviceName ? record?.serviceName : '-'}`}</>
      )
    },
    {
      title: t('Date of Booking'),
      key: 'date',
      render: (text, record) => (
        <p>{dayjs(record.dateOfBooking)?.format('DD-MM-YYYY')}</p>
      )
    },
    {
      title: t('Order Status'),
      key: 'status',
      render: (text, record) => {
        return (
          <div>
            <Status status={record.status} />
          </div>
        )
      }
    },
    {
      title: t('action'),
      render: (text, record) => (
        <div className='d-flex align-items-center gap-2'>
          <img
            src={Theme.icons.icn_view_list}
            alt='icn_user_add'
            style={{ height: '20px', cursor: 'pointer' }}
            onClick={() =>
              navigate(`/jive/orderTracking/ViewAndUpdateOrder/${record.id}`)
            }
          />
        </div>
      ),
      key: 'action'
    }
  ]

  const { data, error, isLoading } = useQuery(
    ['AllOrders', activeKey, pageNumber, pageSize, search, sortOrder],
    () =>
      get(`AdminServiceOrderTracking/GetAllOrders`, {
        Status: activeKey,
        PageNumber: pageNumber,
        PageSize: pageSize,
        Search: search,
        SortColumn: 'Id',
        SortOrder: sortOrder
      }),
    {
      // keepPreviousData: true,
      onError: (err: any) => {
        setAlert({
          message: err?.message || t('An error occurred'),
          type: 'error',
          visible: true
        })
      }
    }
  )
  return (
    <>
      <p className='page-heading'>Order Tracking</p>
      <CustomTabs
        tabs={tabs}
        activeKey={activeKey}
        onTabChange={handleTabChange}
      />

      <>
        <div className='d-flex justify-content-between align-items-center'>
          <p className='m-0 list-heading'>{`List of Orders`}</p>
          <div className='d-flex gap-2'>
            <CInput
              prefix={
                <img
                  alt=''
                  src={Theme.icons.icn_search}
                  style={{ height: '16px' }}
                />
              }
              className='searchInput m-0'
              placeholder={t('Search')}
              onChange={handleSearchChange}
            />
            <div
              className='calendar-div'
              style={{ backgroundColor: '#D0C64F' }}
              onClick={() => handleSorting()}
            >
              <img
                src={Theme.icons.icn_sorting}
                alt='icn_user_add'
                style={{
                  height: '20px',
                  transform:
                    sortOrder === 'z' ? 'rotate(0deg)' : 'rotate(180deg)', // Rotates the icon by 180 degrees for ascending
                  transition: 'transform 0.3s ease-in-out' // Smooth transition for the rotation
                }}
              />
            </div>
          </div>
        </div>
        <CTable
          footer='End of Table'
          columns={columns}
          dataSource={data?.data}
          pagination={{
            showSizeChanger: false,
            showQuickJumper: false,
            current: pageNumber,
            pageSize: pageSize,
            total: data?.totalRecords,
            onChange: handleTableChange,
            showTotal: (total: number, range: [number, number]) => (
              <p>{`Showing ${range[0]} - ${range[1]} of ${total} Results`}</p>
            ),
            itemRender: (page, type, originalElement) => {
              if (type === 'prev') {
                return <p className='detail-label'>{`< Prev`}</p>
              }
              if (type === 'next') {
                return <p className='detail-label'>{`Next >`}</p>
              }
              return originalElement
            }
          }}
          loading={isLoading}
        />
      </>
      <CustomAlert
        message={alert.message}
        type={alert.type}
        visible={alert.visible}
        onClose={() => setAlert({ ...alert, visible: false })}
        duration={3000}
      />
    </>
  )
}

export default OrderTracking
