import React, { useState } from 'react'
import CInput from '../../../../../components/common/CInput'
import { Theme } from '../../../../../Theme'
import CTable from '../../../../../components/common/CTable'
import { ColumnsType } from 'antd/lib/table'
import { useQuery, useQueryClient } from 'react-query'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import CustomAlert from '../../../../../components/common/CAlert'
import _ from 'lodash'
import CButton from '../../../../../components/common/CButton'
import {
  getAllCategories,
  getAllSubCategories
} from '../../../../../services/lov'
import EmptyList from '../../../../../components/common/EmptyList'

interface SubCategoryListProps {
  id: any
  heading: string
  onCountUpdate?:any
}

const SubCategoriesList: React.FC<SubCategoryListProps> = ({ id, heading,onCountUpdate  }) => {
  const [alert, setAlert] = useState<{
    message: string
    type: 'success' | 'error'
    visible: boolean
  }>({
    message: '',
    type: 'success',
    visible: false
  })
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const pageNumber = Number(searchParams.get('PageNumber')) || 1
  const pageSize = Number(searchParams.get('PageSize')) || 10
  const search = searchParams.get('Search') || ''
  const handleTableChange = (page: number, pageSize: number) => {
    setSearchParams(
      {
        // Status: activeKey,
        PageNumber: page.toString(),
        PageSize: pageSize.toString()
      },
      { replace: true }
    )
  }

  const handleSearchChange = _.debounce((e: any) => {
    setSearchParams(
      {
        // Status: activeKey,
        PageNumber: '1',
        PageSize: pageSize.toString(),
        Search: e.target.value
      },
      { replace: true }
    )
  }, 1000)
  const columns: ColumnsType = [
    {
      title: t('id'),
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: t('Sub Category Name (en)'),
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: t('اسم الفئة الفرعية'),
      dataIndex: 'nameAr',
      key: 'nameAr'
    },
    {
      title: t('action'),
      render: (text, record) => (
        <div className='d-flex  align-items-center'>
          {/* <img src={Theme.icons.icn_view_list} alt="icn_user_add" style={{ height: '20px' }} 
        // onClick={() => navigate('/jive/viewUser', {state:{record}})}
         /> */}
          <img
            src={Theme.icons.icn_view_list}
            alt='icn_user_edit'
            style={{ height: '20px', cursor: 'pointer' }}
            onClick={() =>
              navigate(`${redirectUrl}/${record.parentId}/subcategories/view/${record.id}`, {
                state: { record }
              })
            }
          />
        </div>
      ),
      key: 'action'
    }
  ]
  const { data, error, isLoading } = useQuery(
    ['Category',id, pageNumber, pageSize, search],
    () =>
      getAllSubCategories({
        CategoryId: id,
        PageNumber: pageNumber,
        PageSize: pageSize,
        Search: search
      }),
    {
      keepPreviousData: true,
      onError: (err: any) => {
        setAlert({
          message: err?.message || t('An error occurred'),
          type: 'error',
          visible: true
        })
      }
    }
  )
  const isEmpty = !data?.data?.length && !data?.totalRecords
  onCountUpdate(data?.totalRecords)
  const redirectUrl = `/jive/categories`
  return (
    <>
      {isEmpty ? (
        <EmptyList
          heading={t('No Sub Category Created yet!')}
          description={t('There are no sub categories available.')}
          buttonLabel={t('Add New Sub Category')}
          icon={
            <img
              alt=''
              src={Theme.icons.icn_add_btn}
              style={{ height: '20px' }}
              className='icon-white'
            />
          }
          onButtonClick={() => navigate(`${redirectUrl}/${id}/subcategories/add/new`)}
        />
      ) : (
        <>
          <div className='d-flex justify-content-between align-items-center'>
            <p className='m-0 list-heading'>{t(heading)}</p>
            <div className='d-flex justify-content-between'>
              <CInput
                prefix={
                  <img
                    alt=''
                    src={Theme.icons.icn_search}
                    style={{ height: '16px' }}
                  />
                }
                className='searchInput'
                placeholder={t('Search')}
                onChange={handleSearchChange}
              />
              <CButton
                className='addUserBtn'
                onClick={() => navigate(`${redirectUrl}/${id}/subcategories/add/new`)}
              >
                <img
                  src={Theme.icons.icn_add_btn}
                  alt='icn_add_btn'
                  style={{ height: '20px' }}
                />
                <p className='m-0'>{t('Add Sub Category')}</p>
              </CButton>
            </div>
          </div>
          <CTable
            footer='End of Table'
            columns={columns}
            dataSource={data?.data}
            pagination={{
              showPrevNextJumpers: true,
              current: pageNumber,
              pageSize: pageSize,
              total: data?.totalRecords,
              onChange: handleTableChange,
              showTotal: (total: number, range: [number, number]) => (
                <p>{`Showing ${range[0]} - ${range[1]} of ${total} Results`}</p>
              )
            }}
            loading={isLoading}
          />
        </>
      )}
      <CustomAlert
        message={alert.message}
        type={alert.type}
        visible={alert.visible}
        onClose={() => setAlert({ ...alert, visible: false })}
        duration={3000}
      />
    </>
  )
}

export default SubCategoriesList
