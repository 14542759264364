import React, { useState } from 'react'
import { ColumnsType } from 'antd/lib/table'
import { useQuery } from 'react-query'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { Theme } from '../../Theme'
import CustomAlert from '../../components/common/CAlert'
import CInput from '../../components/common/CInput'
import CTable from '../../components/common/CTable'
import { get } from '../../services/api/apiService'
import Status from '../../components/common/Status'
import dayjs from 'dayjs'
import { RangePickerProps } from 'antd/es/date-picker'
import { DatePicker } from 'antd'

const PaymentLogs: React.FC = () => {
  const [alert, setAlert] = useState<{
    message: string
    type: 'success' | 'error'
    visible: boolean
  }>({
    message: '',
    type: 'success',
    visible: false
  })
  const [showDateRangePicker, setShowDateRangePicker] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { RangePicker } = DatePicker

  const pageNumber = Number(searchParams.get('PageNumber')) || 1
  const pageSize = Number(searchParams.get('PageSize')) || 10
  const search = searchParams.get('Search') || ''
  const startDate = searchParams.get('StartDate') || "";
  const endDate = searchParams.get('EndDate') || "";

  // Pagination handling
  const handleTableChange = (page: number, pageSize: number) => {
    setSearchParams(
      { PageNumber: page.toString(), PageSize: pageSize.toString() },
      { replace: true }
    )
  }

  // Handle search input with debounce
  const handleSearchChange = _.debounce((e: any) => {
    setSearchParams(
      {
        PageNumber: '1',
        PageSize: pageSize.toString(),
        Search: e.target.value
      },
      { replace: true }
    )
  }, 1000)

  // Define columns for Payment Logs
  const columns: ColumnsType = [
    {
      title: t('Transaction ID'),
      dataIndex: 'transactionId',
      key: 'transactionId'
    },
    {
      title: t('Customer Name'),
      dataIndex: 'customerName',
      key: 'customerName'
    },
    {
      title: t('Event Type'),
      dataIndex: 'eventTypeName',
      key: 'eventType'
    },
    {
      title: t('Phone Number'),
      dataIndex: 'phoneNumber',
      key: 'phoneNumber'
    },
    {
      title: t('Date'),
      key: 'date',
      render: (text, record) => (
        <p>{dayjs(record.bookingDate)?.format('DD-MM-YYYY')}</p>
      )
    },
    {
      title: t('Status'),
      dataIndex: 'status',
      key: 'status',
      render: status => <Status status={status} />
    }
  ]

  // Fetch Payment Logs data
  const { data, error, isLoading } = useQuery(
    ['PaymentLogs', pageNumber, pageSize, search,startDate, endDate],
    () =>
      get('AdminReports/GetPaymentLogs', {
        PageNumber: pageNumber,
        PageSize: pageSize,
        Search: search,
        StartDate: startDate, 
        EndDate: endDate
      }),
    {
      keepPreviousData: true,
      onError: (err: any) => {
        setAlert({
          message: err?.message || t('An error occurred'),
          type: 'error',
          visible: true
        })
      }
    }
  )
  const disabledDate: RangePickerProps['disabledDate'] = current => {
    // Can not select days before today and today
    return current && current > dayjs().endOf('day')
  }

  const handleSelect = (ranges: any) => {
    if (ranges) {
      const StartDatePicker = dayjs(ranges && ranges[0]).format('YYYY-MM-DD')
      const EndDatePicker = dayjs(ranges && ranges[1]).format('YYYY-MM-DD')

      setSearchParams(
        {
          StartDate: StartDatePicker,
          EndDate: EndDatePicker,
          PageNumber: '1',
          PageSize: pageSize.toString()
        },
        { replace: true }
      )
    }
    setShowDateRangePicker(!showDateRangePicker)
  }
  return (
    <>
      <p className='page-heading'>{t('Payment Logs')}</p>

      <div className='d-flex justify-content-between align-items-center'>
        <p className='m-0 list-heading'>{t('List of Payment Logs')}</p>
        <div className="d-flex justify-content-between gap-2">
           
            <div style={{position:'relative'}}>
            <div className="calendar-div" onClick={() => setShowDateRangePicker(!showDateRangePicker)}
                style={{ cursor: 'pointer' }}>
                <img src={Theme.icons.icn_white_calendar} alt="icn_user_add" style={{ height: '20px' }} className='cursor-pointer' />
            </div>
            {showDateRangePicker && (
                <div style={{ position: 'absolute', zIndex: 1000, marginTop: '10px',width:'250px' }}>
                    <RangePicker onChange={handleSelect} disabledDate={disabledDate} style={{ height: '50px' }} />
                </div>
            )}
            </div>
            <CInput
                prefix={<img alt="" src={Theme.icons.icn_search} style={{ height: "16px" }} />}
                className="searchInput m-0"
                placeholder={t("Search")}
                onChange={handleSearchChange}
            />
        </div>
      </div>
      <CTable
        footer='End of Table'
        columns={columns}
        dataSource={data?.data}
        pagination={{
          showSizeChanger: false,
          showQuickJumper: false,
          current: pageNumber,
          pageSize: pageSize,
          total: data?.totalRecords,
          onChange: handleTableChange,
          showTotal: (total: number, range: [number, number]) => (
            <p>{`Showing ${range[0]} - ${range[1]} of ${total} Results`}</p>
          ),
          itemRender: (page, type, originalElement) => {
            if (type === 'prev') {
              return <p className='detail-label'>{`< Prev`}</p>
            }
            if (type === 'next') {
              return <p className='detail-label'>{`Next >`}</p>
            }
            return originalElement
          }
        }}
        loading={isLoading}
      />
      <CustomAlert
        message={alert.message}
        type={alert.type}
        visible={alert.visible}
        onClose={() => setAlert({ ...alert, visible: false })}
        duration={3000}
      />
    </>
  )
}

export default PaymentLogs
