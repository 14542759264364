import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import CButton from '../../../../components/common/CButton'
import { Theme } from '../../../../Theme'
import { getAllCancelationPolicy, getAllExpiryTime } from '../../../../services/lov'

const ExpiryTime: React.FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [expiryTime, setExpiryTime] = useState<any[]>([])
  const [viewedPolicyId, setViewedPolicyId] = useState<number | null>(null)
  const redirectUrl = `/jive/expiryTime`

  const [alert, setAlert] = useState<{
    message: string
    type: 'success' | 'error'
    visible: boolean
  }>({
    message: '',
    type: 'success',
    visible: false
  })

  const { data, error, isLoading } = useQuery(
    'getAllExpiryTime',
    () =>
        getAllExpiryTime({
        Search: ''
      }),
    {
      keepPreviousData: true,
      onError: (err: any) => {
        setAlert({
          message: err?.message || t('An error occurred'),
          type: 'error',
          visible: true
        })
      }
    }
  )

  useEffect(() => {
    if (data && data.data && data.data.length > 0) {
      console.log('data.data[0]', data.data)
      setExpiryTime(data.data)
    }
  }, [data])

  return (
    <>
      {expiryTime?.map((policy: any) => (
        <div
          className='mt-3'
          style={{
            backgroundColor: Theme.colors.white,
            padding: '18px',
            borderRadius: '16px'
          }}
          key={policy.id}
        >
            {/* <div className='d-flex flex-column' style={{backgroundColor:'green'}}> */}
          <div className='d-flex justify-content-between align-items-center mt-3 mb-3'>
            {policy.id && (
              <>
                <p className='m-0 list-heading'>
           {`Expiry Time For ${policy?.type}`}
                </p>
                {policy.expiryTimeInHour === null && (
                  <CButton
                    className='addUserBtn mb-2'
                    onClick={() => navigate(`${redirectUrl}/add/new`, {state:{record: policy}})}
                  >
                    <img
                      src={Theme.icons.icn_add_btn}
                      alt='icn_add_btn'
                      style={{ height: '20px' }}
                    />
                    <p className='m-0'>{t('Add Expiry Time')}</p>
                  </CButton>
                )}
              </>
            )}
          </div>
        {policy.expiryTimeInHour !== null  && (
              <div className='view-container'>
              <div className='view-container-content'>
                <div>
                  <p className='label m-0'>{t('Expiry Time')}</p>
                  <p className='detail m-0'>{policy.expiryTimeInHour}</p>
                </div>
                <button
                  className='btn-unfilled h-35'
                  onClick={() =>
                    navigate(`${redirectUrl}/edit/${policy.id}`, {
                      state: { record: policy }
                    })
                  }
                >
                  <img
                    src={Theme.icons.icn_edit}
                    alt='icn_user_add'
                    style={{ height: '15px' }}
                  />
                  Edit
                </button>
              </div>
            {/* </div> */}
          </div>
        )}
        </div>
      ))}
    </>
  )
}

export default ExpiryTime;
