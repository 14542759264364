import { useEffect } from 'react'; 
import { Route, Routes } from 'react-router-dom';
import Login from './pages/Auth/Login';
import ForgotPassword from './pages/Auth/Forgot';
import ResetPassword from './pages/Auth/Reset';
import PortalScreen from './pages/layout/PortalScreen';
import ManageUserList from './pages/MangeUsers/ManageInternalUser/ManageUserList';
import AddNewUser from './pages/MangeUsers/ManageInternalUser/AddNewUser';
import i18n from 'i18next';
import 'leaflet/dist/leaflet.css';
import ViewUser from './pages/MangeUsers/ManageInternalUser/ViewUser';
import ManageCustomerList from './pages/MangeUsers/ManageCustomer/ManageCustomerList';
import ManageFreelancerList from './pages/MangeUsers/ManageFreelancer/ManageFreelancerList';
import ViewFreelancer from './pages/MangeUsers/ManageFreelancer/ViewFreelancer';
import EventPolicyManagementList from './pages/Settings/EventPolicyMangement/EventPolicyManagemetList';
import AddEventType from './pages/Settings/EventPolicyMangement/Events/AddEvent';
import AddCity from './pages/Settings/EventPolicyMangement/Cities/AddCity';
import Otp from './pages/Auth/Otp';
import VendorOnboardingList from './pages/OnBoarding/Vendor/VendorOnboardingList';
import VendorOnBoarding from './pages/OnBoarding/Vendor/OnBoarding/VendorOnboarding';
import AddCategories from './pages/Settings/EventPolicyMangement/Categories/AddCategories';
import ViewVendor from './pages/OnBoarding/Vendor/ViewVendor';
import AddSubCategories from './pages/Settings/EventPolicyMangement/Categories/SubCategories/AddSubCategories';
import ServicesAndPackagesList from './pages/ServicesAndPackages/ServicesAndPackagesList';
import ServicesList from './pages/ServicesAndPackages/ServicesList';
import CreateService from './pages/ServicesAndPackages/CreateService';
import ViewService from './pages/ServicesAndPackages/ViewService';
import AddTermAndCondition from './pages/Settings/EventPolicyMangement/TermsAndConditions/AddTermAndConditions';
import AddCancellationPolicy from './pages/Settings/EventPolicyMangement/CancellationPolicy/AddCancellationPolicy';
import AddUpdateCountry from './pages/Settings/EventPolicyMangement/Country/AddUpdateCountry';
import CustomEventList from './pages/ManageBookings/CustomEvent/CustomEventList';
import CustomEventDetails from './pages/ManageBookings/CustomEvent/CustomEventDetails';
import ServiceBookingList from './pages/ManageBookings/ServiceBooking/ServiceBookingList';
import ViewServiceBooking from './pages/ManageBookings/ServiceBooking/ViewServiceBooking';
import AddBooking from './pages/ManageBookings/CustomEvent/AddBooking';
import Notifications from './pages/ManageNotifications/Notifications';
import ViewNotification from './pages/ManageNotifications/ViewNotification';
import ReportList from './pages/Reports/ReportList';
import PdfPreview from './pages/Reports/PdfPreview';
import ViewReport from './pages/Reports/ViewReport';
import ViewProfile from './pages/Settings/Profile/ViewProfile';
import PaymentLogs from './pages/PaymentLogs/PaymentLogs';
import ChangePassword from './pages/Settings/Profile/ChangePassword';
import AddExpiryTime from './pages/Settings/EventPolicyMangement/ExpiryTime/AddExpiryTime';
import ManageVendorList from './pages/MangeUsers/ManageVendor/ManageVendorList';
import ViewPackage from './pages/ServicesAndPackages/ViewPackage';
import CreateUpdatePackage from './pages/ServicesAndPackages/CreateUpdatePackage';
import PromoCodesList from './pages/PromoCodes/PromoCodesList';
import PromoCodeForm from './pages/PromoCodes/PromoCodeForm';
import PromoCodeView from './pages/PromoCodes/PromoCodeView';
import OrderTracking from './pages/OrderTracking/OrderTracking';
import ViewAndUpdateOrder from './pages/OrderTracking/ViewAndUpdateOrder';
import AdminBookingList from './pages/ManageBookings/AdminBooking/AdminBooking';
import AdminBookingForm from './pages/ManageBookings/AdminBooking/AdminBookingForm';
import AdminBookingDetails from './pages/ManageBookings/AdminBooking/AdminBookingDetails';
import UserFeedback from './pages/Feedback/UserFeedback';
import ViewFeedback from './pages/Feedback/ViewUserFeedback';
import UserFeedbackForm from './pages/Auth/UserFeedbackForm';
import PdfPreviewReport from './pages/Reports/PdfPreciewReport';
import PermissionsManagement from './pages/Permissions/Permissions';
import Dashboard from './pages/Dashboard/Dashboard';
import AuditLogs from './pages/AuditLogs/AuditLogs';

function App() {

  

useEffect(() => {
  const currentLanguage = i18n.language;
  document.documentElement.dir = currentLanguage === 'ar' ? 'rtl' : 'ltr';
}, [i18n.language]);


useEffect(() => {
  const handleLanguageChange = (lng: string) => {
    document.documentElement.dir = lng === 'ar' ? 'rtl' : 'ltr';
  };

  i18n.on('languageChanged', handleLanguageChange);

  // Clean up the listener when the component unmounts
  return () => {
    i18n.off('languageChanged', handleLanguageChange);
  };
}, []);

  return (
    <Routes>
    <Route index element={<Login />} />
    <Route path="forgot" element={<ForgotPassword />} />
    <Route path="otp" element={<Otp />} />
    <Route path="reset" element={<ResetPassword />} />
    <Route path="add-feedback" element={<UserFeedbackForm />} />

    <Route path="jive" element={<PortalScreen />} >
    <Route path="manageInternalUser" element={<ManageUserList />} />
    <Route path="manageCustomer" element={<ManageCustomerList />} />
    <Route path="manageVendor" element={<ManageVendorList />} />
    <Route path="manageFreelancer" element={<ManageFreelancerList />} />
    <Route path="vendorList" element={<VendorOnboardingList />} />
    <Route path="eventPolicyManagementList" element={<EventPolicyManagementList />} />
    <Route path="servicesAndPackagesList" element={<ServicesAndPackagesList />} />
    <Route path="viewPackage/:id" element={<ViewPackage />} />
    <Route path="createUpdatePackage" element={<CreateUpdatePackage />} />
    <Route path="reports" element={<ReportList />} />
    <Route path="servicesList/:id" element={<ServicesList />} />
    <Route path="serviceBooking" element={<ServiceBookingList />} />
    <Route path="addNewUser" element={<AddNewUser />} />
    <Route path="eventType/:type/:id" element={<AddEventType />} />
    <Route path="expiryTime/:type/:id" element={<AddExpiryTime />} />
    <Route path="categories/:type/:id" element={<AddCategories />} />
    <Route path="categories/:ParentId/subcategories/:type/:id" element={<AddSubCategories />} />
    <Route path="term-and-conditions/:type/:id" element={<AddTermAndCondition />} />
    <Route path="cancellation-policy/:type/:id" element={<AddCancellationPolicy />} />
    <Route path="customEvent" element={<CustomEventList />} />
    <Route path="customEvent/:type/:id" element={<CustomEventDetails />} />
    <Route path="customEvent/:requestId/booking/:type/:id" element={<AddBooking />} />
    <Route path="notifications" element={<Notifications />} />
    <Route path="notification/:type/:id" element={<ViewNotification />} />
    <Route path="vendorOnboarding" element={<VendorOnBoarding />} />
    {/* <Route path="country/:type/:id" element={<AddUpdateCountry />} /> */}
    <Route path="country/view/:id" element={<AddUpdateCountry />} />
    <Route path="country/:countryId/cities/:type/:id" element={<AddCity />} />
    <Route path="createService" element={<CreateService />} />
    <Route path="viewUser" element={<ViewUser />} />
    <Route path="viewFreelancer" element={<ViewFreelancer />} />
    <Route path="viewVendor" element={<ViewVendor />} />
    <Route path="viewServiceBooking/:id" element={<ViewServiceBooking />} />
    <Route path="viewService/:id" element={<ViewService />} />
    <Route path="pdfPreview" element={<PdfPreview />} />
    <Route path="pdfReportPreview" element={<PdfPreviewReport />} />
    <Route path="viewReport" element={<ViewReport />} />
    <Route path="profile" element={<ViewProfile />} />
    <Route path="paymentLogs" element={<PaymentLogs />} />
    <Route path="promoCodesList" element={<PromoCodesList />} />
    <Route path="promoCodeForm" element={<PromoCodeForm />} />
    <Route path="promoCodeView/:id" element={<PromoCodeView />} />
    <Route path="profile/changePassword" element={<ChangePassword />} />
    <Route path="orderTracking" element={<OrderTracking />} />
    <Route path="orderTracking/ViewAndUpdateOrder/:id" element={<ViewAndUpdateOrder />} />
    <Route path="adminBooking" element={<AdminBookingList />} />
    <Route path="adminBooking/:type/:id" element={<AdminBookingForm />} />
    <Route path="adminBooking/view-details/:id" element={<AdminBookingDetails />} />
    <Route path="feedback" element={<UserFeedback />} />
    <Route path="feedback/view-details/:id" element={<ViewFeedback />} />
    <Route path="permissions" element={<PermissionsManagement />} />
    <Route path="dashboard" element={<Dashboard />} />
    <Route path="auditLogs" element={<AuditLogs />} />


    
     </Route>
    
    </Routes>
  );
}

export default App;
