import React, { useState } from 'react'
import { Theme } from '../../../Theme'
import CustomAlert from '../../../components/common/CAlert'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useMutation, useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'
import CButton from '../../../components/common/CButton'
import {
  approvedAdminServiceOrder,
  getAdminServiceOrderById,
  rejectAdminServiceOrder
} from '../../../services/booking'
import CustomTabs from '../../../components/common/CTabs'
import AlertModal from '../../../components/common/AlertModal'
import SuccessModal from '../../../components/common/SuccessModal'
import {
  formatFileSize,
  getTheFirstTwoLetterOfString
} from '../../../utils/functions'
import { Modal } from 'antd'
import TimerCountDown from '../../../components/common/TimerCountDown'

const tabs = [
  { key: 'Booking Details', label: 'Booking Details' },
  { key: 'Vendor Details', label: 'Vendor Details' }
]

const ViewServiceBooking: React.FC = () => {
  const navigate = useNavigate()
  const params = useParams()
  const location = useLocation()
  const { t } = useTranslation()
  const [alert, setAlert] = useState<{
    message: string
    type: string
    visible: boolean
  }>({
    message: '',
    type: 'success',
    visible: false
  })
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [switchChecked, setSwitchChecked] = useState('')
  const [isSuccess, setIsSuccess] = useState(false)
  const [previewImg, setPreviewImg] = useState('')
  const [visible, setVisible] = useState(false)

  const {
    data: statusData,
    isLoading: statusLoading,
    error: statusError,
    refetch
  } = useQuery(
    ['statusServiceOrder', params.id],
    () =>
      switchChecked === 'reject'
        ? rejectAdminServiceOrder({ orderId: params?.id })
        : approvedAdminServiceOrder({ orderId: params?.id }), // Replace with your fetch function
    {
      enabled: false,
      onSuccess: data => {
        setIsSuccess(true)
      }
    }
  )

  const { data, isLoading, error } = useQuery(
    ['serviceOrderById', params?.id],
    () => getAdminServiceOrderById({ Id: params?.id })
  )

  const {
    serviceName,
    categoryName,
    vendorName,
    totalPrice,
    vendorId,
    adminApproved,
    serviceRequestStartTime,
    serviceRequestEndTime,
    serviceLocation,
    features,
    description,
    serviceImages,
    status,
    orderCancellationDate
  } = data?.data || {}

  const handleTabChange = (key: string) => {
    if (key === 'Vendor Details') {
      navigate('/jive/viewVendor', { state: { id: vendorId } })
    }
  }

  function subtractTimes (
    startTime: string | undefined,
    endTime: string | undefined
  ) {
    if (!startTime || !endTime) {
      return 'Invalid time values'
    }

    // Parse the time strings into Date objects
    const start = new Date(`1970-01-01T${convertTo24HourFormat(startTime)}`)
    const end = new Date(`1970-01-01T${convertTo24HourFormat(endTime)}`)

    // Get the difference in milliseconds
    const diffInMs = end.getTime() - start.getTime()

    // If the difference is negative (end time is on the next day)
    if (diffInMs < 0) {
      return 'End time is before start time'
    }

    // Convert milliseconds to minutes
    const diffInMinutes = Math.floor(diffInMs / (1000 * 60))

    // Convert the difference to hours and minutes
    const hours = Math.floor(diffInMinutes / 60)
    const minutes = diffInMinutes % 60

    return `${hours} hour and ${minutes} minute`
  }

  // Helper function to convert 12-hour time to 24-hour time
  function convertTo24HourFormat (time: string) {
    if (!time) {
      throw new Error('Invalid time format')
    }

    const [timePart, modifier] = time.split(' ')
    if (!timePart || !modifier) {
      throw new Error('Invalid time format')
    }

    let [hours, minutes] = timePart.split(':').map(Number)

    if (modifier === 'PM' && hours !== 12) {
      hours += 12
    } else if (modifier === 'AM' && hours === 12) {
      hours = 0
    }

    return `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}:00`
  }

  const result = subtractTimes(serviceRequestStartTime, serviceRequestEndTime)

  const handleModalOk = () => {
    refetch()
    setIsModalVisible(false)
  }

  const handleModalCancel = () => {
    setIsModalVisible(false)
  }

  const callRequest = (val: string) => {
    setSwitchChecked(val)
    setIsModalVisible(true)
  }

  const previewImage = (file: any) => {
    console.log('my file===>>>', file)
    setPreviewImg(file)
    setVisible(true)
  }

  return (
    <div style={{ color: Theme.colors.black }}>
      <div className='d-flex align-items-center justify-content-between mb-3'>
        <div className='d-flex align-items-center'>
          <img
            src={Theme.icons.icn_arrow_back}
            className='cursor-pointer'
            alt='icn_user_add'
            style={{ height: '20px' }}
            onClick={() => navigate(-1)}
          />
          <p className='list-heading m-0 mx-2'>{t('Booking Request')}</p>
        </div>
        {(adminApproved !== null || adminApproved !== false) &&
          status != 'Expired' &&
          status != 'Rejected' &&
          status != 'Approved' && (
            <div className='d-flex align-items-center w-25'>
              <CButton
                style={{
                  marginRight: '12px',
                  backgroundColor: Theme.colors.success,
                  borderColor: Theme.colors.success,
                  width: '100%'
                }}
                onClick={() => callRequest('accept')}
              >
                Accept
              </CButton>
              <CButton
                className='rejectBtn'
                style={{ colo: 'red' }}
                onClick={() => callRequest('reject')}
              >
                Reject
              </CButton>
            </div>
          )}
      </div>

      <CustomTabs
        tabs={tabs}
        activeKey={'Booking Details'}
        onTabChange={handleTabChange}
      />
      <div
        className='mt-3'
        style={{
          backgroundColor: Theme.colors.white,
          padding: '24px',
          borderRadius: '16px'
        }}
      >
        <div className='d-flex align-items-center justify-content-between mb-3'>
          <p className='user-name m-0'>{t('Booking Details')}</p>
        </div>
        <div
          style={{
            backgroundColor: '#F3F3F3',
            padding: '24px',
            borderRadius: '16px'
          }}
        >
          <div className='d-flex align-items-center justify-content-between'>
            <div className='d-flex align-items-center'>
              <div className='bold-name-circle'>
                <p className='m-0'>
                  {getTheFirstTwoLetterOfString(vendorName)}
                </p>
              </div>
              <p className='user-name m-0 mx-2'>{vendorName}</p>
            </div>

            {status == 'VendorApproved' && orderCancellationDate && (
              <TimerCountDown
                heading='Request Expire'
                icon={Theme.icons.icn_timer_countdown}
                backgroundColor={Theme.colors.white}
                time={orderCancellationDate}
              />
            )}
          </div>
          <div className='row mt-4'>
            <div className='col-lg-4'>
              <p className='detail-label m-0'>{t('Vendor Name')}</p>
              <p className='detail-value'>{vendorName}</p>
            </div>
            <div className='col-lg-4'>
              <p className='detail-label m-0'>{t('Service Name')}</p>
              <p className='detail-value'>{serviceName}</p>
            </div>
            <div className='col-lg-4'>
              <p className='detail-label m-0'>{t('Duration')}</p>
              <p className='detail-value'>{result}</p>
            </div>
            <div className='col-lg-4'>
              <p className='detail-label m-0'>{t('Currency')}</p>
              <p className='detail-value'>{`SAR`}</p>
            </div>
            <div className='col-lg-4'>
              <p className='detail-label m-0'>{t('Service Location')}</p>
              <p className='detail-value'>{serviceLocation}</p>
            </div>
            <div className='col-lg-4'>
              <p className='detail-label m-0'>{t('Service Category')}</p>
              <p className='detail-value'>{categoryName}</p>
            </div>
            <div className='col-lg-4'>
              <p className='detail-label m-0'>{t('Features')}</p>
              <p className='detail-value'>{features}</p>
            </div>
            <div className='col-lg-4'>
              <p className='detail-label m-0'>{t('Price')}</p>
              <p className='detail-value'>{totalPrice}</p>
            </div>
            <div className='col-lg-8'>
              <p className='detail-label m-0'>{t('Description')}</p>
              <p className='detail-value'>{description}</p>
            </div>
          </div>
        </div>

        <div
          className='mt-3'
          style={{
            backgroundColor: '#F3F3F3',
            padding: '24px',
            borderRadius: '16px'
          }}
        >
          <p className='user-name m-0'>{t('Service Images')}</p>
          <div className='row'>
            {serviceImages?.map((file: any, index: number) => (
              <div className='col-lg-3 cursor-pointer' key={index}>
                <div
                  className='d-flex align-items-center p-3 preview-image-view-container mt-3'
                  onClick={() =>
                    file?.fileContentType !== 'application/pdf' &&
                    previewImage(
                      process.env.REACT_APP_S3_BUCKET_URL + file.path
                    )
                  }
                >
                  <div className='d-flex align-items-center'>
                    <img
                      src={process.env.REACT_APP_S3_BUCKET_URL + file.path}
                      alt={file.originalFileName}
                      className='category-icon mb-0'
                      style={{ width: '50px', height: '50px' }}
                    />
                    <div className='mx-2'>
                      <p
                        className='detail-label m-0'
                        style={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis'
                        }}
                      >
                        {file.originalFileName}
                      </p>
                      <p className='detail-label m-0'>
                        {formatFileSize(file.fileLength)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Modal open={visible} onCancel={() => setVisible(false)} footer={null}>
        <img src={previewImg} style={{ width: '100%' }} />
      </Modal>
      <AlertModal
        visible={isModalVisible}
        message={`Are you sure you want to ${switchChecked} the request?`}
        onDelete={handleModalOk}
        onCancel={handleModalCancel}
      />
      <SuccessModal
        visible={isSuccess}
        message={`You have successfully ${switchChecked}ed booking request`}
        onOk={() => navigate('/jive/serviceBooking')}
      />
      <CustomAlert
        message={alert.message}
        type={alert.type as 'success' | 'info' | 'warning' | 'error'}
        visible={alert.visible}
        onClose={() => setAlert({ ...alert, visible: false })}
        duration={3000}
      />
    </div>
  )
}

export default ViewServiceBooking
