import React, { useState, useEffect } from "react";
import CInput from "../../../../components/common/CInput";
import { Theme } from "../../../../Theme";
import CTable from "../../../../components/common/CTable";
import { ColumnsType } from 'antd/lib/table';
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Switch, Tag } from "antd";
import { changeCustomerStatus, getAllCustomers } from "../../../../services/customer";
import AlertModal from "../../../../components/common/AlertModal";
import CustomAlert from "../../../../components/common/CAlert";
import _ from 'lodash';
import CButton from "../../../../components/common/CButton";
import { getAllEvents } from "../../../../services/lov";
import EmptyList from "../../../../components/common/EmptyList";


const tabs = [
    { key: 'event', label: 'Type of Event' },
    // { key: 'category', label: 'Categories' },
    { key: 'city', label: 'Cities' },
    // { key: 'termsAndCondition', label: 'Terms & Conditions' },
    // { key: 'cancellationPolicy', label: 'Cancellation Policy' },
];

const EventList: React.FC = () => {
    const [userWillActive, setUserWillActive] = useState<{ show: boolean; status: boolean; id: number | null }>({
        show: false,
        status: false,
        id: null,
    });
    const [alert, setAlert] = useState<{ message: string; type: 'success' | 'error'; visible: boolean }>({
        message: '',
        type: 'success',
        visible: false,
    });
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    // Get activeKey, pageNumber, and pageSize from URL search params
    // const activeKey = searchParams.get('Status') || 'AllUser';
    const pageNumber = Number(searchParams.get('PageNumber')) || 1;
    const pageSize = Number(searchParams.get('PageSize')) || 10;
    const search = searchParams.get('Search') || "";

    // Update search params when the tab changes
    // const handleTabChange = (key: string) => {
    //     setSearchParams({ Status: key, PageNumber: '1', PageSize: pageSize.toString() }, { replace: true });
    // };

    // Pagination handling
    const handleTableChange = (page: number, pageSize: number) => {
        setSearchParams({ 
            // Status: activeKey, 
            PageNumber: page.toString(), 
            PageSize: pageSize.toString() }, { replace: true });
    };

    const handleSearchChange =  _.debounce((e:any) => {
        setSearchParams({ 
            // Status: activeKey, 
            PageNumber: '1', PageSize: pageSize.toString(), Search: e.target.value }, { replace: true });
    },1000)

    // const handleSwitchChange = (user: any) => {
    //     setUserWillActive({ show: true, status: user?.active, id: user?.id });
    // };

    const columns: ColumnsType = [
        {
            title: t("id"),
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: t("Event Type"),
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: t("نوع الحدث"),
            dataIndex: 'nameAr',
            key: 'nameAr',
        },
        {
            title: t('action'),
            render: (text, record) => (
                <div className="d-flex  align-items-center">
                   
        {/* <img src={Theme.icons.icn_view_list} alt="icn_user_add" style={{ height: '20px' }} 
        // onClick={() => navigate('/jive/viewUser', {state:{record}})}
         /> */}
        <img src={Theme.icons.icn_view_list} alt="icn_user_edit" style={{ height: '20px',cursor:'pointer' }} 
        onClick={() => navigate(`${redirectUrl}/view/${record.id}`, {state:{record}})} 
        />
                </div>
            ),
            key: 'action',
        },
    ];

    const { data, error, isLoading } = useQuery(
        ['EventType', pageNumber, pageSize, search],
        () => getAllEvents({ PageNumber: pageNumber, PageSize: pageSize, Search: search}),
        {
            keepPreviousData: true,
            onError: (err: any) => {
                setAlert({ message: err?.message || t("An error occurred"), type: 'error', visible: true });
            },
        }
    );
// console.log("data",data);

    // const { mutate, isLoading: statusLoading } = useMutation(changeCustomerStatus, {
    //     onMutate: async (newStatus: any) => {
    //         await queryClient.cancelQueries(['customerUsers', activeKey]);
    //         const previousData = queryClient.getQueryData(['customerUsers', activeKey]);
    //         return { previousData };
    //     },
    //     onError: (err: any, newStatus, context) => {
    //         queryClient.setQueryData(['customerUsers', activeKey], context?.previousData);
    //         setAlert({ message: err?.message || t("Failed to update status"), type: 'error', visible: true });
    //     },
    //     onSettled: () => {
    //         queryClient.invalidateQueries(['customerUsers', activeKey]);
    //     },
    //     onSuccess: () => {
    //         setAlert({ message: t("Status Updated successfully!"), type: 'success', visible: true });
    //     },
    // });

    // const handleModalOk = () => {
    //     if (userWillActive.id !== null) {
    //         const newStatus = !userWillActive.status;
    //         mutate({ id: userWillActive.id, status: newStatus });
    //     }
    //     setUserWillActive({ show: false, status: false, id: null });
    // };

    const handleModalCancel = () => {
        setUserWillActive({ show: false, status: false, id: null });
    };
    const isEmpty = !data?.data?.length && !data?.totalRecords;
    const redirectUrl = `/jive/eventType`
    return (
        <>
            {/* <p className="page-heading">{t("event_policy_management")}</p>
            <CustomTabs tabs={tabs} activeKey={activeKey} onTabChange={handleTabChange} /> */}
 {isEmpty ? (
        <EmptyList
          heading={t("No Event Type Created yet!")}
          description={t("There are no event types available.")}
          buttonLabel={t("Add Event Type")}
          icon={<img alt="" src={Theme.icons.icn_add_btn} style={{ height: '20px' }} className="icon-white" />}
          onButtonClick={() => navigate(`${redirectUrl}/add/new`)}
        />
      ) : (
        <>
            <div className="d-flex justify-content-between align-items-center">
                <p className="m-0 list-heading">{t("List of Event Types")}</p>
                <div className="d-flex justify-content-between">
                    <CInput
                        prefix={<img alt="" src={Theme.icons.icn_search} style={{ height: "16px" }} />}
                        className="searchInput"
                        placeholder={t("Search")}
                        onChange={handleSearchChange}
                    />
                    <CButton className="addUserBtn" onClick={() => navigate(`${redirectUrl}/add/new`)}>
                <img src={Theme.icons.icn_add_btn} alt="icn_add_btn" style={{ height: '20px' }} />
                    <p className="m-0">{t("add_event_type")}</p>
                </CButton>
                </div>
            </div>
            <CTable
                footer="End of Table"
                columns={columns}
                dataSource={data?.data}
                pagination={{
                    showSizeChanger:false,
                    showQuickJumper:false,
                    current: pageNumber,
                    pageSize: pageSize,
                    total: data?.totalRecords,
                    onChange: handleTableChange,
                    showTotal: (total: number, range: [number, number]) => (
                        <p>{`Showing ${range[0]} - ${range[1]} of ${total} Results`}</p>
                    ),
                    itemRender: (page, type, originalElement) => {
                        if (type === 'prev') {
                            return <p className="detail-label">{`< Prev`}</p>;
                        }
                        if (type === 'next') {
                            return <p className="detail-label">{`Next >`}</p>;
                        }
                        return originalElement;
                    }
                }}
                loading={isLoading}
            />
            </>
        )}
            {/* <AlertModal
                visible={userWillActive?.show}
                message={t(`Are you sure you want to ${userWillActive?.status ? 'deactivate' : 'activate'} this user?`)}
                // onDelete={handleModalOk}
                // onCancel={handleModalCancel}
            /> */}
            <CustomAlert
                message={alert.message}
                type={alert.type}
                visible={alert.visible}
                onClose={() => setAlert({ ...alert, visible: false })}
                duration={3000}
            />
        </>
    );
};

export default EventList;
