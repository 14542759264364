import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Theme } from '../../../Theme'
import { get } from '../../../services/booking'
import { useMutation, useQuery } from 'react-query'
import { t } from 'i18next'
import Status from '../../../components/common/Status'
import dayjs from 'dayjs'
import CButton from '../../../components/common/CButton'
import CustomAlert from '../../../components/common/CAlert'
import AlertModal from '../../../components/common/AlertModal'
import { getTheFirstTwoLetterOfString } from '../../../utils/functions'
import moment from 'moment'
import { Form, Modal } from 'antd'
// import { createPaymentInvoice } from '../../../services/freelancer'
import { post } from '../../../services/api/apiService'
import Link from '../../../components/common/Link'

const AdminBookingDetails: React.FC = () => {
  const navigate = useNavigate()
  const { type, id } = useParams()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [switchChecked, setSwitchChecked] = useState('')
  const [isSuccess, setIsSuccess] = useState(false)
  const [details, setDetails] = useState<{ [key: string]: any }>({})
  const buttonRef = useRef<HTMLDivElement>(null)
  const [modalPosition, setModalPosition] = useState({ top: 0, right: 0 })
  const [isVisible, setIsVisible] = useState(false)
  const [confirmationMessage, setConfirmationMessage] = useState('')

  const [alert, setAlert] = useState<{
    message: string
    type: string
    visible: boolean
  }>({
    message: '',
    type: 'success',
    visible: false
  })

  const {
    mutate: fetchAdminBookingDetails,
    isLoading: isFetchingAdminBookingDetails
  } = useMutation((id: any) => get('AdminServiceOrder/GetById', { Id: id }), {
    onSuccess: response => {
      if (response.succeeded) {
        setDetails(response.data)
      } else {
        setAlert({ message: response.message, type: 'error', visible: true })
      }
    },
    onError: (error: any) => {
      setAlert({
        message: error?.message || 'An error occurred. Please try again.',
        type: 'error',
        visible: true
      })
    }
  })

  const showModal = () => {
    if (buttonRef.current) {
      const { top, right, height } = buttonRef.current.getBoundingClientRect()
      setModalPosition({ top: top + height, right: window.innerWidth - right })
    }
    setIsVisible(true)
  }

  const generateLink = async () => {
    let url = `/PayFort/GeneratePaymentLink?OrderId=${id ? parseInt(id) : 0}`
    let response = await post(url, {})
    if (response.succeeded) {
      setAlert({
        message: 'Payment Invoice link generated successfully!',
        type: 'success',
        visible: true
      })
      fetchAdminBookingDetails(id)
    } else {
      setAlert({ message: response.message, type: 'error', visible: true })
    }
  }

  const verifyPaymentAndUpdateOrderStatus = async () => {
    let url = `/PayFort/VerifyPaymentAndUpdateOrderStatus?OrderId=${id}`
    let response = await post(url, {})
    if (response.succeeded) {
      setSwitchChecked('')
      setAlert({
        message:
          'The payment has been verified, and the booking status has been successfully updated.',
        type: 'success',
        visible: true
      })
      fetchAdminBookingDetails(id)
    } else {
      setAlert({ message: response.message, type: 'error', visible: true })
    }
  }

  useEffect(() => {
    if (id !== 'new') {
      fetchAdminBookingDetails(id)
    }
  }, [id, fetchAdminBookingDetails])

  const handleModalOk = async () => {
    // await fetchAdminBookingDetails(id)
    let obj = { orderId: id ? parseInt(id) : 0 }
    if (switchChecked == 'Verify Payment') {
      verifyPaymentAndUpdateOrderStatus()
    } else {
      generateLink()
    }
    setIsModalVisible(false)
    setIsVisible(false)
  }

  const handleModalCancel = () => {
    setIsModalVisible(false)
  }

  const callRequest = (val: string) => {
    setIsVisible(false)
    if (val != 'Verify Payment') {
      setConfirmationMessage(
        val
          ? `Are you sure you want to ${
              val === 'Generate' ? 'Generate' : 'Re-Generate'
            } Payment Link?`
          : ``
      )
    } else {
      setConfirmationMessage(
        `Are you sure you have verified the payment and want to update the booking status?`
      )
    }
    setSwitchChecked(val)

    setIsModalVisible(true)
  }

  return (
    <>
      <div style={{ padding: '20px 0px', color: Theme.colors.black }}>
        <div className='d-flex align-items-center justify-content-between'>
          <div className='d-flex align-items-center'>
            <img
              src={Theme.icons.icn_arrow_back}
              className='cursor-pointer'
              alt='icn_user_add'
              style={{ height: '20px' }}
              onClick={() => navigate(-1)}
            />
            <p className='list-heading m-0 mx-2'>{t('Admin Booking')}</p>
          </div>
          {details?.status == 'Approved' && details?.paymentLink != null && (
            <div className='d-flex align-items-center w-20'>
              {/* <p className='list-heading m-0 mx-2'>{t('Verify Payment')}</p> */}

              <CButton
                style={{
                  marginRight: '12px',
                  backgroundColor: Theme.colors.success,
                  borderColor: Theme.colors.success,
                  width: '100%'
                }}
                onClick={() => callRequest('Verify Payment')}
              >
                Verify Payment
              </CButton>
            </div>
          )}
        </div>
      </div>

      {id != 'add' && (
        <>
          {details?.status == 'Approved' && (
            <div
              className='mb-3'
              style={{
                backgroundColor: Theme.colors.white,
                padding: '24px',
                borderRadius: '16px',
                display: 'flex',
                gap: '20px',
                alignItems: 'center'
              }}
            >
              <p className='list-heading m-0'>{t('Booking Request')}</p>

              <p className='m-0'>
                <Status status={details?.status} />
              </p>
            </div>
          )}

          <div
            style={{
              backgroundColor: Theme.colors.white,
              padding: '24px',
              borderRadius: '16px'
            }}
          >
            <div className='d-flex justify-content-between align-items-center'>
              <p className='list-heading m-0 mx-2'>{t('Booking Details')}</p>
              <div className='d-flex align-items-center gap-3'>
                {details?.status === 'Approved' && !details?.paymentLink && (
                  <div
                    className='d-flex align-items-center justify-content-center'
                    style={{
                      height: '30px',
                      width: '30px',
                      borderRadius: '8px',
                      backgroundColor: '#CBF2FF'
                    }}
                    ref={buttonRef}
                    onClick={showModal}
                  >
                    <img
                      src={Theme.icons.icn_link}
                      alt='icn_user_company'
                      style={{ height: '20px' }}
                      className='cursor-pointer'
                    />
                  </div>
                )}
                {/* {details?.status === 'Pending' && (
                  <CButton
                    className='btn-unfilled h-35'
                    onClick={() =>
                      navigate(
                        `/jive/adminBooking/edit/${details?.orderReference}`,
                        {
                          state: {
                            record: details,
                            formType: 'update'
                          }
                        }
                      )
                    }
                  >
                    <img
                      src={Theme.icons.icn_edit}
                      alt='icn_user_add'
                      style={{ height: '15px' }}
                    />
                    Edit
                  </CButton>
                )} */}
              </div>
            </div>
            <div className='view-container mt-3'>
              <div className='d-flex align-items-center'>
                <div className='bold-name-circle'>
                  <p className='m-0'>
                    {getTheFirstTwoLetterOfString(details?.customerName)}
                  </p>
                </div>
                <p className='user-name m-0 mx-2'>{details?.customerName}</p>
              </div>
              <div className='row mt-3' style={{ width: '90%' }}>
                <div className='col-lg-4'>
                  <p className='detail-label m-0'>Date</p>
                  <p className='detail-value'>
                    {dayjs(details?.eventDate)?.format('DD-MM-YYYY')}
                  </p>
                </div>
                <div className='col-lg-4'>
                  <p className='detail-label m-0'>Customer Phone Number </p>
                  <p className='detail-value'>{details?.phoneNumber}</p>
                </div>
                <div className='col-lg-4'>
                  <p className='detail-label m-0'>Customer Email </p>
                  <p className='detail-value'>{details?.customerEmail}</p>
                </div>
                <div className='col-lg-4'>
                  <p className='detail-label m-0'>Service Name </p>
                  <p className='detail-value'>{details?.serviceName}</p>
                </div>
                <div className='col-lg-4'>
                  <p className='detail-label m-0'>Service Location </p>
                  <p className='detail-value'>{details?.userAddress}</p>
                </div>
                <div className='col-lg-4'>
                  <p className='detail-label m-0'>Service Category </p>
                  <p className='detail-value'>{details?.categoryName}</p>
                </div>

                <div className='col-lg-4'>
                  <p className='detail-label m-0'>Vendor Name </p>
                  <p className='detail-value'>{details?.vendorName}</p>
                </div>
                <div className='col-lg-4'>
                  <p className='detail-label m-0'>Vendor Phone Number </p>
                  <p className='detail-value'>{details?.vendorPhoneNumber}</p>
                </div>
                <div className='col-lg-4'>
                  <p className='detail-label m-0'>Vendor Email Address </p>
                  <p className='detail-value'>{details?.vendorEmail}</p>
                </div>

                <div className='col-lg-4'>
                  <p className='detail-label m-0'>Time </p>
                  <p className='detail-value'>
                    {details?.serviceRequestStartTime} -{' '}
                    {details?.serviceRequestEndTime}
                  </p>
                </div>
                <div className='col-lg-4'>
                  <p className='detail-label m-0'>Currency </p>
                  <p className='detail-value'>{'SAR'}</p>
                </div>
                <div className='col-lg-4'>
                  <p className='detail-label m-0'>Price </p>
                  <p className='detail-value'>{details?.servicePrice}</p>
                </div>
                <div className='col-lg-4'>
                  <p className='detail-label m-0'>No of Attendees </p>
                  <p className='detail-value'>{details?.noOfAttendees}</p>
                </div>
                <div className='col-lg-12'>
                  <p className='detail-label m-0'>Description </p>
                  <p className='detail-value'>{details?.description}</p>
                </div>
              </div>
              {details?.paymentLink &&
                details?.status != 'Pending' &&
                details?.status != 'EventCompleted' &&
                details?.status != 'Paid' && (
                  <Link
                    icon={Theme.icons.icn_link}
                    link={details?.paymentLink}
                    isbutton={true}
                    btnText='Copy'
                    heading='Payment Link'
                  />
                )}
              {details?.orderReference &&
                details?.status == 'EventCompleted' && (
                  <Link
                    icon={Theme.icons.icn_link}
                    link={
                      'https://admin-dev.jiveksa.com/add-feedback?Ref=' + details?.orderReference
                    }
                    isbutton={true}
                    btnText='Copy'
                    heading='Feedback Link'
                  />
                )}
            </div>
          </div>
        </>
      )}

      <Modal
        title='Payment Invoice'
        visible={isVisible}
        footer={null}
        className='w-300'
        // onOk={() => setIsVisible(false)}
        onCancel={() => setIsVisible(false)}
        centered
        style={{
          top: modalPosition.top,
          right: modalPosition.right,
          position: 'absolute',
          width: 'min-content'
        }}
        mask={false} // Disable overlay to show over the button only
      >
        <p>
          {t(
            'Click here to create payment invoice link. Use this link to access the virtual session.'
          )}
        </p>
        <CButton
          disabled={false}
          className='mt-4 auth-btn'
          onClick={() => callRequest('Generate')}
        >
          {t('Generate Payment Link')}
        </CButton>
      </Modal>

      <AlertModal
        visible={isModalVisible}
        message={confirmationMessage}
        isCustomBtnText={true}
        customButtonLabels={{ txtCancel: 'No', txtOk: 'Yes' }}
        onDelete={handleModalOk}
        onCancel={handleModalCancel}
      />

      <CustomAlert
        message={alert.message}
        type={alert.type as 'success' | 'info' | 'warning' | 'error'}
        visible={alert.visible}
        onClose={() => setAlert({ ...alert, visible: false })}
        duration={3000}
      />
    </>
  )
}

export default AdminBookingDetails
