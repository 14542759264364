import React, { useState } from 'react'
import CustomTabs from '../../../components/common/CTabs'
import CInput from '../../../components/common/CInput'
import { Theme } from '../../../Theme'
import CTable from '../../../components/common/CTable'
import { ColumnsType } from 'antd/lib/table'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import CustomAlert from '../../../components/common/CAlert'
import {
  getAdminSentServiceOrders,
  getAllAdminServiceOrder
} from '../../../services/booking'
import _ from 'lodash'
import { Tag } from 'antd'
import Status from '../../../components/common/Status'
import CButton from '../../../components/common/CButton'

const tabs = [
  { key: 'VendorBooking', label: 'Vendor Booking' },
  { key: 'Approved', label: 'Approved' },
  { key: 'Booked', label: 'Booked' },
  { key: 'Completed', label: 'Completed' },
  { key: 'Cancelled', label: 'Cancelled' }
  //   { key: 'Expired', label: 'Expired' }
]

const AdminBookingList: React.FC = () => {
  const [alert, setAlert] = useState<{
    message: string
    type: 'success' | 'error'
    visible: boolean
  }>({
    message: '',
    type: 'success',
    visible: false
  })
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const activeKey = searchParams.get('Status') || 'VendorBooking'
  const pageNumber = Number(searchParams.get('PageNumber')) || 1
  const pageSize = Number(searchParams.get('PageSize')) || 10
  const search = searchParams.get('Search') || ''
  const sortOrder = searchParams.get('SortOrder') || 'z'

  const handleTabChange = (key: string) => {
    setSearchParams(
      { Status: key, PageNumber: '1', PageSize: pageSize.toString() },
      { replace: true }
    )
  }

  // Pagination handling
  const handleTableChange = (page: number, pageSize: number) => {
    setSearchParams(
      {
        Status: activeKey,
        PageNumber: page.toString(),
        PageSize: pageSize.toString()
      },
      { replace: true }
    )
  }

  const handleSearchChange = _.debounce((e: any) => {
    setSearchParams(
      {
        Status: activeKey,
        PageNumber: '1',
        PageSize: pageSize.toString(),
        Search: e.target.value
      },
      { replace: true }
    )
  }, 1000)

  const handleSorting = () => {
    const newSortOrder = sortOrder === 'z' ? 'a' : 'z'
    setSearchParams(
      {
        Status: activeKey,
        PageNumber: pageNumber.toString(),
        PageSize: pageSize.toString(),
        Search: search,
        SortOrder: newSortOrder
      },
      { replace: true }
    )
  }

  const columns: ColumnsType = [
    {
      title: t('ID'),
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: t('Customer Name'),
      dataIndex: 'customerName',
      key: 'customerName'
    },
    {
      title: t('Phone Number'),
      dataIndex: 'phoneNumber',
      key: 'phoneNumber'
    },
    {
      title: t('Vendor Name'),
      dataIndex: 'vendorName',
      key: 'vendorName'
    },
    {
      title: t('Status'),
      dataIndex: 'status',
      key: 'status',
      render: (text, record) =>
         <Status status={record.status} />

    //   <>
    //   {' '}
    //   {activeKey == 'Booked' ? (
    //     <Status status='Paid' />
    //   ) : (
    //     <Status status={record.status} />
    //   )}
    // </>
    },
    {
      title: t('action'),
      render: (text, record) => (
        <div className='d-flex  align-items-center'>
          <img
            src={Theme.icons.icn_view_list}
            alt='icn_user_add'
            style={{ height: '20px' }}
            className='cursor-pointer'
            onClick={() =>
              navigate(
                `/jive/adminBooking/view-details/${record?.id}`
              )
            }
          />
        </div>
      ),
      key: 'action'
    }
  ]

  const { data, error, isLoading } = useQuery(
    [
      'getAdminSentServiceOrders',
      activeKey,
      pageNumber,
      pageSize,
      search,
      sortOrder
    ],
    () =>
      getAdminSentServiceOrders({
        Status: activeKey,
        PageNumber: pageNumber,
        PageSize: pageSize,
        Search: search,
        SortColumn: 'Id',
        SortOrder: sortOrder
      }),
    {
      // keepPreviousData: true,
      onError: (err: any) => {
        setAlert({
          message: err?.message || t('An error occurred'),
          type: 'error',
          visible: true
        })
      }
    }
  )

  return (
    <>
      <p className='page-heading'>{t('Admin Booking')}</p>
      <CustomTabs
        tabs={tabs}
        activeKey={activeKey}
        onTabChange={handleTabChange}
      />

      <div className='d-flex justify-content-between align-items-center'>
        <p className='m-0 list-heading'>{`Booking List`}</p>
        <div className='d-flex justify-content-between'>
          <CInput
            prefix={
              <img
                alt=''
                src={Theme.icons.icn_search}
                style={{ height: '16px' }}
              />
            }
            className='searchInput'
            placeholder={t('Search')}
            onChange={handleSearchChange}
          />
          <CButton
            className='addUserBtn'
            onClick={() =>
              navigate(`/jive/adminBooking/add/new`, {
                state: { record: undefined, formType: 'create' }
              })
            }
          >
            <img
              src={Theme.icons.icn_add_service}
              alt='icn_manage_booking'
              style={{ height: '20px' }}
            />
            <p className='m-0'>{t('Create Booking')}</p>
          </CButton>
        </div>
      </div>
      <CTable
        footer='End of Table'
        columns={columns}
        dataSource={data?.data}
        pagination={{
          showSizeChanger: false,
          showQuickJumper: false,
          current: pageNumber,
          pageSize: pageSize,
          total: data?.totalRecords,
          onChange: handleTableChange,
          showTotal: (total: number, range: [number, number]) => (
            <p>{`Showing ${range[0]} - ${range[1]} of ${total} Results`}</p>
          ),
          itemRender: (page, type, originalElement) => {
            if (type === 'prev') {
              return <p className='detail-label'>{`< Prev`}</p>
            }
            if (type === 'next') {
              return <p className='detail-label'>{`Next >`}</p>
            }
            return originalElement
          }
        }}
        loading={isLoading}
      />
      <CustomAlert
        message={alert.message}
        type={alert.type}
        visible={alert.visible}
        onClose={() => setAlert({ ...alert, visible: false })}
        duration={3000}
      />
    </>
  )
}

export default AdminBookingList
