import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { getAdminUserRole } from '../../services/manageUser';
import { Col, Form, Row, Typography, Divider, Spin, Table, Checkbox } from 'antd';
import { get, post } from '../../services/api/apiService';
import CustomSelect from '../../components/common/CSelect';
import CButton from '../../components/common/CButton'; 
import CustomAlert from '../../components/common/CAlert';
import { t } from 'i18next';

const { Title } = Typography;

const permissionsHeadings = ['Pages', 'Add', 'Edit','Read', 'Accept / Reject', 'Enabled / Disabled' , 'Download',];

const PermissionsManagement: React.FC = () => {
  const [selectedRole, setSelectedRole] = useState<string | undefined>(undefined);
  const [permissionsData, setPermissionsData] = useState<any[]>([]);
  const [changedPermissions, setChangedPermissions] = useState<any[]>([]);
  const [alert, setAlert] = useState<{
    message: string
    type: string
    visible: boolean
  }>({
    message: '',
    type: 'success',
    visible: false
  })

  const { data, isLoading } = useQuery('adminUserRoles', getAdminUserRole);

  const getData = () => {
    if (!data) return [];
    return data.data.map((x: any) => ({
      value: x.id,
      label: x.roleName,
    }));
  };

  const { mutate: getAllRolePermissions, isLoading: isFetchingPermissions } = useMutation(
    (roleId: string) => get(`Permissions/GetAllRolePermissions?roleId=${roleId}`, {}),
    {
      onSuccess: (response) => {
        setPermissionsData([])
        if (response.succeeded) {
          setPermissionsData(response.data);
        }
      },
      onError: (error) => {
        console.error('Error fetching permissions:', error);
      },
    }
  );

  const handleRoleChanged = (value: any) => {
    setSelectedRole(value);
    getAllRolePermissions(value);
  };

  const handleCheckboxChange = (
    record: any,
    menuId: string,
    subMenuId: string | null,
    field: string,
    checked: boolean
  ) => {
    // console.log("record",record);
    
    setPermissionsData((prevData) =>
      prevData.map((menuItem) => {
        if (menuItem.id === menuId) {
          if (subMenuId) {
            return {
              ...menuItem,
              subMenuList: menuItem.subMenuList.map((subItem: any) =>
                subItem.id === subMenuId ? { ...subItem, [field]: checked } : subItem
              ),
            };
          } else {
            return { ...menuItem, [field]: checked };
          }
        }
        return menuItem;
      })
    );

    setChangedPermissions((prevChanges) => {
      const newChanges = [...prevChanges];
      const existingChangeIndex = newChanges.findIndex(
        (change) => change.menuId === menuId && change.subMenuId === subMenuId && change.field === field
      );

      if (existingChangeIndex > -1) {
        newChanges[existingChangeIndex] = { menuId, subMenuId, field, checked };
      } else {
        newChanges.push({ menuId, subMenuId, field, checked });
      }

      return newChanges;
    });
  };

  const columns = [
    {
      title: permissionsHeadings[0], // Pages
      dataIndex: 'menuTitle',
      key: 'menuTitle',
      render: (text: string) => <strong>{text}</strong>,
    },
    ...[
      { field: 'fnAdd', headingIndex: 1, value: 'fnMenuAdd' },
      { field: 'fnEdit', headingIndex: 2, value: 'fnMenuEdit' },
      { field: 'fnRead', headingIndex: 3, value: 'fnMenuRead' },
      { field: 'fnAcceptReject', headingIndex: 4, value: 'fnMenuAcceptReject' },
      { field: 'fnEnableDisabled', headingIndex: 5, value: 'fnMenuEnableDisabled' },
      { field: 'fnExport', headingIndex: 6, value: 'fnMenuExport' },
    ].map(({ field, headingIndex, value }) => ({
      title: permissionsHeadings[headingIndex],
      dataIndex: field,
      key: field,
      render: (checked: boolean, record: any) =>
        record.isParent && record.children ? null : (
          record[value] ? (
            <Checkbox
              className="custom-checkbox"
              checked={checked}
              onChange={(e) =>
                handleCheckboxChange(record, record.menuId, record.subMenuId, field, e.target.checked)
              }
            />
          ) : null
        ),
    })),
  ];
  
  

  const getTableData = () =>
    permissionsData.map((menuItem) => {
      const hasChildren = menuItem.subMenuList && menuItem.subMenuList.length > 0;

      return {
        key: `menu-${menuItem.id}`,
        menuId: menuItem.id,
        menuTitle: menuItem.menuTitle,
        ...menuItem,
        isParent: true,
        children: hasChildren
          ? menuItem.subMenuList.map((subItem: any) => ({
              key: `submenu-${subItem.id}`,
              menuId: menuItem.id,
              subMenuId: subItem.id,
              menuTitle: subItem.menuTitle,
              ...subItem,
              isParent: false,
            }))
          : undefined,
      };
    });

    const onPermissionsSave = async () => {
        let url = 'Permissions/AddUpdateRoleWisePermission';
      
        let updatedPermissions: any[] = [];
      
        // Create the updatedPermissions based on the changedPermissions
        changedPermissions.forEach((change) => {
          const { menuId, subMenuId, field, checked } = change;
      
          // Find the menu item that was changed
          const menuItem = permissionsData.find((menu) => menu.id === menuId);
          if (menuItem) {
            // Check if the permission already exists in updatedPermissions
            const existingPermissionIndex = updatedPermissions.findIndex(
              (permission) => permission.menuId === menuId && permission.claimValue === menuItem.menuTitle
            );
      
            if (subMenuId) {
              // Handle submenu change
              const subMenu = menuItem.subMenuList.find((sub: any) => sub.id === subMenuId);
      
              if (subMenu) {
                // Check if submenu permission exists
                const existingSubMenuPermissionIndex = updatedPermissions.findIndex(
                  (permission) => permission.menuId === subMenu.id && permission.claimValue === subMenu.menuTitle
                );
      
                if (existingSubMenuPermissionIndex > -1) {
                  // If submenu permission exists, update it
                  updatedPermissions[existingSubMenuPermissionIndex] = {
                    menuId: subMenu.id,
                    roleId: selectedRole,
                    claimType: "permission",
                    claimValue: subMenu.menuTitle,
                    fnAdd: subMenu.fnAdd,
                    fnRead: subMenu.fnRead,
                    fnEdit: subMenu.fnEdit,
                    fnDelete: subMenu.fnDelete,
                    fnPrint: subMenu.fnPrint,
                    fnExport: subMenu.fnExport,
                    fnEnableDisabled: subMenu.fnEnableDisabled,
                    fnAcceptReject: subMenu.fnAcceptReject,
                    parentId:subMenu.parentId,
                    id: 0, 
                  };
      
                  // Ensure parent menu exists in updatedPermissions if any submenu is present
                  if (existingPermissionIndex === -1) {
                    updatedPermissions.push({
                      menuId : menuItem.id,
                      roleId: selectedRole,
                      claimType: "permission",
                      claimValue: menuItem.menuTitle,
                      fnAdd: menuItem.fnAdd,
                      fnRead: menuItem.fnRead,
                      fnEdit: menuItem.fnEdit,
                      fnDelete: menuItem.fnDelete,
                      fnPrint: menuItem.fnPrint,
                      fnExport: menuItem.fnExport,
                      fnEnableDisabled: menuItem.fnEnableDisabled,
                      fnAcceptReject: menuItem.fnAcceptReject,
                    parentId:menuItem.parentId,

                      id: 0, 
                    });
                  }
                } else {
                  // Add new submenu permission
                  updatedPermissions.push({
                    menuId : subMenu.id,
                    roleId: selectedRole,
                    claimType: "permission",
                    claimValue: subMenu.menuTitle,
                    fnAdd: subMenu.fnAdd,
                    fnRead: subMenu.fnRead,
                    fnEdit: subMenu.fnEdit,
                    fnDelete: subMenu.fnDelete,
                    fnPrint: subMenu.fnPrint,
                    fnExport: subMenu.fnExport,
                    fnEnableDisabled: subMenu.fnEnableDisabled,
                    fnAcceptReject: subMenu.fnAcceptReject,
                    parentId:subMenu.parentId,
                    id: 0, // Assuming id is 0 as required
                  });
      
                  // Ensure parent menu exists in updatedPermissions if any submenu is present
                  if (existingPermissionIndex === -1) {
                    updatedPermissions.push({
                      menuId : menuItem.id,
                      roleId: selectedRole,
                      claimType: "permission",
                      claimValue: menuItem.menuTitle,
                      fnAdd: menuItem.fnAdd,
                      fnRead: menuItem.fnRead,
                      fnEdit: menuItem.fnEdit,
                      fnDelete: menuItem.fnDelete,
                      fnPrint: menuItem.fnPrint,
                      fnExport: menuItem.fnExport,
                      fnEnableDisabled: menuItem.fnEnableDisabled,
                      fnAcceptReject: menuItem.fnAcceptReject,
                    parentId:menuItem.parentId,

                      id: 0, // Assuming id is 0 as required
                    });
                  }
                }
              }
            } else {
              // Handle parent menu change
              if (existingPermissionIndex > -1) {
                // If parent permission exists, update it
                updatedPermissions[existingPermissionIndex] = {
                  menuId: menuItem.id,
                  roleId: selectedRole,
                  claimType: "permission",
                  claimValue: menuItem.menuTitle,
                  fnAdd: menuItem.fnAdd,
                  fnRead: menuItem.fnRead,
                  fnEdit: menuItem.fnEdit,
                  fnDelete: menuItem.fnDelete,
                  fnPrint: menuItem.fnPrint,
                  fnExport: menuItem.fnExport,
                  fnEnableDisabled: menuItem.fnEnableDisabled,
                  fnAcceptReject: menuItem.fnAcceptReject,
                  parentId:menuItem.parentId,
                  id: 0, 
                };
              } else {
                // Add new parent menu permission
                updatedPermissions.push({
                  menuId: menuItem.id,
                  roleId: selectedRole,
                  claimType: "permission",
                  claimValue: menuItem.menuTitle,
                  fnAdd: menuItem.fnAdd,
                  fnRead: menuItem.fnRead,
                  fnEdit: menuItem.fnEdit,
                  fnDelete: menuItem.fnDelete,
                  fnPrint: menuItem.fnPrint,
                  fnExport: menuItem.fnExport,
                  fnEnableDisabled: menuItem.fnEnableDisabled,
                  fnAcceptReject: menuItem.fnAcceptReject,
                  parentId:menuItem.parentId,
                  id: 0, 
                });
              }
      
              menuItem.subMenuList.forEach((subMenu: any) => {
                // Remove duplication for submenus
                const existingSubMenuPermissionIndex = updatedPermissions.findIndex(
                  (permission) => permission.menuId === menuId && permission.claimValue === subMenu.menuTitle
                );
      
                if (existingSubMenuPermissionIndex === -1) {
                  updatedPermissions.push({
                    menuId,
                    subMenuId: subMenu.id,
                    roleId: selectedRole,
                    claimType: "permission",
                    claimValue: subMenu.menuTitle,
                    fnAdd: subMenu.fnAdd,
                    fnRead: subMenu.fnRead,
                    fnEdit: subMenu.fnEdit,
                    fnDelete: subMenu.fnDelete,
                    fnPrint: subMenu.fnPrint,
                    fnExport: subMenu.fnExport,
                    fnEnableDisabled: subMenu.fnEnableDisabled,
                    fnAcceptReject: subMenu.fnAcceptReject,
                    parentId:subMenu.parentId,
                    id: 0,
                  });
                }
              });
            }
          }
        });   

        let updatedPermissionsFiltered = updatedPermissions.filter(permission => {
            if (permission.parentId) {
                const allFlagsFalse = !permission.fnAdd && !permission.fnRead && !permission.fnEdit && !permission.fnDelete && !permission.fnPrint && !permission.fnExport && !permission.fnEnableDisabled && !permission.fnAcceptReject;
                return !allFlagsFalse;  
            }
            return true;  
        });
        // console.log("updatedPermissionsFiltered",updatedPermissionsFiltered);
        
        const finalPermissions = updatedPermissionsFiltered.filter(permission => {
            if (permission.parentId === null) {
                const hasActiveSubmenu = updatedPermissions.some(submenu => submenu.parentId === permission.menuId &&
                    (submenu.fnAdd || submenu.fnRead || submenu.fnEdit || submenu.fnDelete || submenu.fnPrint || submenu.fnExport || submenu.fnEnableDisabled || submenu.fnAcceptReject));
                return hasActiveSubmenu || !(!permission.fnAdd && !permission.fnRead && !permission.fnEdit && !permission.fnDelete && !permission.fnPrint && !permission.fnExport && !permission.fnEnableDisabled && !permission.fnAcceptReject); // If parent has no active submenus, but at least one flag is true, it remains
            }
            return true; 
        });
                
        // console.log("finalPermissions",finalPermissions);

       
           
        if (finalPermissions.length > 0) {
          await post(url, finalPermissions).then((response) => {
            if (response.succeeded) {
              setAlert({message: "Permissions updated successfully!", type: 'success', visible: true})
              handleRoleChanged(selectedRole);
            // setPermissionsData([])
            // setSelectedRole(undefined)
            } else {
              setAlert({message: response?.message, type: 'error', visible: true})
            }
          }).catch((error) => {
            setAlert({ message: error, type: 'error', visible: true        })
          });
        } else {
          setAlert({
          message: "Please select any permission",
          type: 'error',
          visible: true
        })
        }
      };
      

  return (
    <div className="permissions-container" style={{ padding: '20px', backgroundColor: '#fff' }}>
      <Title level={2} style={{ marginBottom: '10px' }}>
        Permission & Management
      </Title>
      <Divider />
      <Row gutter={16}>
        <Col span={6}>
          <Form.Item name="RoleId" rules={[{ required: true, message: 'Please select a role' }]}>
            <CustomSelect
              label="User Role"
              options={getData()}
              value={selectedRole}
              onChange={handleRoleChanged}
              placeholder="Choose Role"
            />
          </Form.Item>
        </Col>
      </Row>

      {isFetchingPermissions ? (
        <Spin size="large" style={{ display: 'block', textAlign: 'center', marginTop: '50px' }} />
      ) : (
        permissionsData.length > 0 && (
          <Table
            columns={columns}
            dataSource={getTableData()}
            pagination={false}
            bordered
            expandable={{
              expandRowByClick: true,
              rowExpandable: (record) => record.isParent && record.children,
            }}
            rowClassName={(record) => (record.isParent && record.children ? 'parent-row-highlight' : '')}
          />
        )
      )}

      {selectedRole && 
        <Row justify="end" style={{ marginTop: '20px' }}>
            <Col span={4}>
            <CButton loading={isFetchingPermissions} style={{ width: '100%' }} onClick={onPermissionsSave}>
                Save
            </CButton>
            </Col>
        </Row>
        }
      
      <CustomAlert
        message={alert.message}
        type={alert.type as 'success' | 'info' | 'warning' | 'error'}
        visible={alert.visible}
        onClose={() => setAlert({ ...alert, visible: false })}
        duration={3000}
      />
    </div>
  );
};

export default PermissionsManagement;
