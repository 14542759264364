import React, { useEffect, useState } from 'react'
import { Form, Row, Col } from 'antd'
import CInput from '../../../components/common/CInput'
import { Theme } from '../../../Theme'
import CButton from '../../../components/common/CButton'
import { get, post } from '../../../services/api/apiService'
import CustomAlert from '../../../components/common/CAlert'
import SuccessModal from '../../../components/common/SuccessModal'
import { useNavigate } from 'react-router-dom'
import { t } from 'i18next'

const ViewProfile: React.FC = () => {
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const [type, setType] = useState('view')
  const [isSuccess, setIsSuccess] = useState(false)
  const [alert, setAlert] = useState<{
    message: string
    type: string
    visible: boolean
  }>({
    message: '',
    type: 'success',
    visible: false
  })
  const [userDetails, setUserDetails] = useState<{ [key: string]: any }>({})

  const fetchUserDetails = () => {
    get('AdminAccount/GetMyProfile', {}).then(response => {
      if (response.succeeded) {
        setUserDetails(response.data)
      } else {
        setAlert({ message: response.message, type: 'error', visible: true })
      }
    })
  }

  useEffect(() => {
    if (type !== 'add') {
      fetchUserDetails()
    }
  }, [type])

  const handleSave = (values: any) => {
    const apiEndpoint =
      type === 'edit' ? 'AdminAccount/UpdateProfile' : 'AdminAccount/AddProfile'

    post(apiEndpoint, {
      ...values,
      ...(userDetails.id && {
        id: userDetails.id,
        EnFullName: userDetails.fullName
      })
    }).then((response: any) => {
      if (response.succeeded) {
        setIsSuccess(true)
      } else {
        setAlert({ message: response.message, type: 'error', visible: true })
      }
    })
  }

  const getTitle = () => {
    if (type === 'view') return 'Admin Details'
    return type === 'edit' ? 'Edit Profile' : 'Add Profile'
  }

  const handleEdit = () => {
    setType('edit')
    form.setFieldsValue(userDetails)
  }

  const handleOk = () => {
    setIsSuccess(false)
    setType('view')
  }

  return (
    <>
      <div
        style={{
          backgroundColor: Theme.colors.white,
          padding: '24px',
          borderRadius: '16px'
        }}
      >
        <div className='d-flex align-items-center justify-content-between'>
          <p className='list-heading m-0 mx-2'>{getTitle()}</p>
          <div className='d-flex align-items-center justify-content-between gap-2'>
            {type === 'view' && (
              <button
                className='btn-unfilled h-35'
                onClick={() => handleEdit()}
              >
                <img
                  src={Theme.icons.icn_edit}
                  alt='icn_user_add'
                  style={{ height: '15px' }}
                />
                Edit
              </button>
            )}
            {userDetails?.userType != 'SuperAdmin' && (
              <button
                className='btn-unfilled h-35'
                onClick={() =>
                  navigate(`/jive/profile/changePassword`, {
                    state: { record: userDetails }
                  })
                }
              >
                <img
                  src={Theme.icons.icn_edit}
                  alt='icn_user_add'
                  style={{ height: '15px' }}
                />
                Change Password
              </button>
            )}
          </div>
        </div>
        {type === 'view' ? (
          <div className='view-container mt-3'>
            <div className='d-flex align-items-center'>
              <div className='bold-name-circle'>
                <p className='m-0'>{userDetails?.fullName?.substring(0, 2)}</p>
              </div>
              <p className='user-name m-0 mx-2'>{userDetails?.fullName}</p>
            </div>
            <div
              className='view-container-content mt-3'
              style={{ width: '65%' }}
            >
              <div>
                <p className='detail-label m-0'>User Role</p>
                <p className='detail-value m-0'>{userDetails?.userType}</p>
              </div>
              <div>
                <p className='detail-label m-0'>Email</p>
                <p className='detail-value m-0'>{userDetails?.email}</p>
              </div>
              <div>
                <p className='detail-label m-0'>Phone</p>
                <p className='detail-value m-0'>{userDetails?.phoneNumber}</p>
              </div>
            </div>
          </div>
        ) : (
          <Form
            form={form}
            layout='vertical'
            onFinish={handleSave}
            style={{
              backgroundColor: Theme.colors.white,
              padding: '24px',
              borderRadius: '16px'
            }}
          >
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  name='fullName'
                  label='Full Name'
                  rules={[
                    { required: true, message: 'Please enter your full name' }
                  ]}
                >
                  <CInput placeholder='Enter Full Name' />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name='email'
                  label='Email'
                  rules={[
                    { required: true, message: 'Please enter your email' }
                  ]}
                >
                  <CInput disabled={true} placeholder='Enter Email' />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name='phoneNumber'
                  label='Phone'
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your phone number'
                    }
                  ]}
                >
                  <CInput disabled={true} placeholder='Enter Phone Number' />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  name='userType'
                  label='User Role'
                  rules={[
                    { required: true, message: 'Please select the user role' }
                  ]}
                >
                  <CInput disabled={true} placeholder='Enter User Role' />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={8} style={{ marginTop: '15px' }}>
              <Col span={4}>
                <CButton style={{ width: '100%' }} htmlType='submit'>
                  {type === 'edit' ? 'Update' : 'Save'}
                </CButton>
              </Col>
            </Row>
          </Form>
        )}

        <SuccessModal
          visible={isSuccess}
          message={
            type === 'edit'
              ? 'Profile updated successfully.'
              : 'Profile added successfully.'
          }
          onOk={() => handleOk()}
        />
        <CustomAlert
          message={alert.message}
          type={alert.type as 'success' | 'info' | 'warning' | 'error'}
          visible={alert.visible}
          onClose={() => setAlert({ ...alert, visible: false })}
          duration={3000}
        />
      </div>
    </>
  )
}

export default ViewProfile
